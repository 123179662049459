import React, { useState, useEffect } from "react";
import {
  Container,
  Checkbox,
  Icon,
  Table,
  Panel,
  InputGroup,
  Whisper,
  Tooltip,
  Input,
  Loader,
  Pagination,
  Grid,
  Row,
  Col,
  Tag,
  Badge,
  ButtonToolbar,
  Button,
  Modal,
  Progress,
  SelectPicker,
} from "rsuite";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import moment from "moment";
import { CSVLink } from "react-csv";
import Highlighter from "react-highlight-words";
import { useApp } from "../../AppStore";
import Transfer from "./transfer";
import Details from "./details";
import Import from "../import";
import New from "./new";
import GQL from "./graphql";

const todayStart = moment()
  .utc()
  .subtract(24, "hours")
  .format("YYYY-MM-DD HH:mm:ss");
const twoDaysStart = moment()
  .utc()
  .subtract(48, "hours")
  .format("YYYY-MM-DD HH:mm:ss");
const weekStart = moment()
  .utc()
  .subtract(7, "days")
  .format("YYYY-MM-DD HH:mm:ss");
const monthStart = moment()
  .startOf("month")
  .utc()
  .format("YYYY-MM-DD HH:mm:ss");

export default (props) => {
  const { id } = props;
  /** Global Variables */
  const [
    { globalFilters, menuExpanded, globalSearch, user, triggerAccountDetails },
    actions,
  ] = useApp();

  /** Component Variables */
  const limit = parseInt((window.innerHeight - 235) / 45);

  /** Component State */
  const [showExportModal, setShowExportModal] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [accounts, setAccounts] = useState([]);
  const [accountFilter, setAccountFilter] = useState(user.account_id);
  const [accountFilterName, setAccountFilterName] = useState("");
  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [reportedToday, setReportedToday] = useState(null);
  const [reportedTwoDays, setReportedTwoDays] = useState(null);
  const [reportedThisWeek, setReportedThisWeek] = useState(null);
  const [reportedThisMonth, setReportedThisMonth] = useState(null);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [transferAccount, setTransferAccount] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(id ? true : false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [showMassImportModal, setShowMassImportModal] = useState(false);
  const [showNewAccountModal, setShowNewAccountModal] = useState(false);
  const [sortColumn, setSortColumn] = useState("name");
  const [sortType, setSortType] = useState("asc");

  /** GraphQL */
  const { data, loading, refetch } = useQuery(GQL.Queries.accounts, {
    variables: {
      offset: (page - 1) * limit,
      limit,
      get_children: true,
      ...(accountFilter ? { id: accountFilter } : {}),
      ...(globalSearch.length > 0 ? { name: globalSearch } : {}),
      sortType,
      sortField: sortColumn,
    },
    onCompleted: (data) => {
      //console.log({data});
      setAccounts(data.accounts);
    },
    fetchPolicy: "network-only",
  });
  const [
    load_export_data,
    {
      data: export_data,
      loading: loading_export_data,
      refetch: refetch_export_data,
    },
  ] = useLazyQuery(GQL.Queries.accounts);
  const {
    data: reported_today_count,
    loading: loading_reported_today_count,
    refetch: refetch_today,
  } = useQuery(GQL.Queries.reported_count, {
    variables: {
      start: todayStart,
      ...(accountFilter ? { account_id: accountFilter } : {}),
    },
    onCompleted: (data) => {
      //console.log("Loaded today:", { data });
      checkAndSetReportedToday();
    },
  });
  const {
    data: reported_twodays_count,
    loading: loading_reported_twodays_count,
    refetch: refetch_twodays,
  } = useQuery(GQL.Queries.reported_count, {
    variables: {
      start: twoDaysStart,
      ...(accountFilter ? { account_id: accountFilter } : {}),
    },
    onCompleted: (data) => {
      checkAndSetReportedTwoDays();
    },
  });
  const {
    data: reported_week_count,
    loading: loading_reported_week_count,
    refetch: refetch_week,
  } = useQuery(GQL.Queries.reported_count, {
    variables: {
      start: weekStart,
      ...(accountFilter ? { account_id: accountFilter } : {}),
    },
    onCompleted: (data) => {
      checkAndSetReportedThisWeek();
    },
  });
  const {
    data: reported_month_count,
    loading: loading_reported_month_count,
    refetch: refetch_month,
  } = useQuery(GQL.Queries.reported_count, {
    variables: {
      start: monthStart,
      ...(accountFilter ? { account_id: accountFilter } : {}),
    },
    onCompleted: (data) => {
      checkAndSetReportedThisMonth();
    },
  });
  const {
    data: total,
    loading: loading_total,
    refetch: refetch_total,
  } = useQuery(GQL.Queries.device_count, {
    variables: {
      get_children: true,
      ...(accountFilter ? { account_id: accountFilter } : {}),
    },
    onCompleted: (total) => {
      //console.log("Loaded Total:", {total});

      checkAndSetReportedToday();
      checkAndSetReportedTwoDays();
      checkAndSetReportedThisWeek();
      checkAndSetReportedThisMonth();
    },
  });
  const {
    data: account_count,
    loading: loading_account_count,
    refetch: refetch_count,
  } = useQuery(GQL.Queries.account_count, {
    variables: {
      get_children: true,
      id: accountFilter ? accountFilter : user.account_id,
      ...(globalSearch.length > 0 ? { name: globalSearch } : {}),
    },
    onCompleted: (account_count) => {
      //console.log({ account_count, limit });
      const pages = Math.ceil(account_count.account_count / limit);
      //console.log({ pages });
      setPages(pages);
      if (page > pages) {
        setPage(pages);
        //setOffset(pages*limit);
      }
    },
  });

  const checkAndSetReportedToday = () => {
    if (
      !loading_reported_today_count &&
      !loading_total &&
      total &&
      total.device_count &&
      reported_today_count &&
      reported_today_count.reported_count
    ) {
      //console.log('setting today count:', parseInt(parseFloat(reported_today_count.reported_count / total.device_count) * 100))
      setReportedToday(
        parseInt(
          parseFloat(reported_today_count.reported_count / total.device_count) *
            100
        )
      );
    } else {
      //console.log('today count not ready')
    }
  };

  const checkAndSetReportedTwoDays = () => {
    if (
      total &&
      total.device_count &&
      reported_twodays_count &&
      reported_twodays_count.reported_count
    ) {
      setReportedTwoDays(
        parseInt(
          parseFloat(
            reported_twodays_count.reported_count / total.device_count
          ) * 100
        )
      );
    }
  };

  const checkAndSetReportedThisWeek = () => {
    if (
      total &&
      total.device_count &&
      reported_week_count &&
      reported_week_count.reported_count
    ) {
      setReportedThisWeek(
        parseInt(
          parseFloat(reported_week_count.reported_count / total.device_count) *
            100
        )
      );
    }
  };

  const checkAndSetReportedThisMonth = () => {
    if (
      total &&
      total.device_count &&
      reported_month_count &&
      reported_month_count.reported_count
    ) {
      setReportedThisMonth(
        parseInt(
          parseFloat(reported_month_count.reported_count / total.device_count) *
            100
        )
      );
    }
  };

  const handleCheckAccount = (id, checked) => {
    if (checked) {
      setSelectedIds([...selectedIds, id]);
    } else {
      let newIds = [...selectedIds];
      newIds.splice(newIds.indexOf(id), 1);
      setSelectedIds(newIds);
    }
  };

  useEffect(() => {
    //setReportedToday(null);
    //setReportedTwoDays(null);
    //setReportedThisWeek(null);
    //setReportedThisMonth(null);

    refetch();
    refetch_today();
    refetch_twodays();
    refetch_week();
    refetch_month();
    refetch_count();
    refetch_total();
  }, [accountFilter, limit, showDetailsModal]);

  useEffect(() => {
    refetch();
    refetch_count();
    refetch_total();
  }, [globalSearch]);

  useEffect(() => {
    if (triggerAccountDetails === true) {
      setShowDetailsModal(true);
      actions.setTriggerAccountDetails(false);
    }
  }, [triggerAccountDetails]);

  //console.log({reportedToday});

  return (
    <Container
      style={{
        paddingTop: 0,
        textAlign: "center",
        //backgroundColor: "white"
      }}
    >
      <Grid style={{ width: "100%" }}>
        <Row>
          <Col xs={6} style={{ textAlign: "center" }}>
            <div>
              <span>
                {!loading_reported_today_count
                  ? reported_today_count && reported_today_count.reported_count
                    ? reported_today_count.reported_count
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : 0
                  : "Loading..."}{" "}
                /{" "}
                {!loading_total
                  ? total.device_count
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "Loading..."}{" "}
                Reported Today
              </span>
              <Progress.Line
                percent={
                  !loading_reported_today_count && !loading_total
                    ? reportedToday
                    : 100
                }
                showInfo={false}
                status={
                  loading_reported_today_count || loading_total
                    ? "active"
                    : "success"
                }
              />
            </div>
          </Col>
          <Col xs={6} style={{ textAlign: "center" }}>
            <div>
              <span>
                {!loading_reported_twodays_count
                  ? reported_twodays_count.reported_count
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "Loading..."}{" "}
                /{" "}
                {!loading_total
                  ? total.device_count
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "Loading..."}{" "}
                Reported in the last 2 days
              </span>
              <Progress.Line
                percent={
                  !loading_reported_twodays_count && !loading_total
                    ? reportedTwoDays
                    : 100
                }
                showInfo={false}
                status={
                  loading_reported_twodays_count || loading_total
                    ? "active"
                    : "success"
                }
              />
            </div>
          </Col>
          <Col xs={6} style={{ textAlign: "center" }}>
            <div>
              <span>
                {!loading_reported_week_count
                  ? reported_week_count.reported_count
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "Loading..."}{" "}
                /{" "}
                {!loading_total
                  ? total.device_count
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "Loading..."}{" "}
                Reported This Week
              </span>
              <Progress.Line
                percent={
                  !loading_reported_week_count && !loading_total
                    ? reportedThisWeek
                    : 100
                }
                showInfo={false}
                status={
                  loading_reported_week_count || loading_total
                    ? "active"
                    : "success"
                }
              />
            </div>
          </Col>
          <Col xs={6} style={{ textAlign: "center" }}>
            <div>
              <span>
                {!loading_reported_month_count
                  ? reported_month_count.reported_count
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "Loading..."}{" "}
                /{" "}
                {!loading_total
                  ? total.device_count
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "Loading..."}{" "}
                Reported in {moment().format("MMMM")}
              </span>
              <Progress.Line
                percent={
                  !loading_reported_month_count && !loading_total
                    ? reportedThisMonth
                    : 100
                }
                showInfo={false}
                status={
                  loading_reported_month_count || loading_total
                    ? "active"
                    : "success"
                }
              />
            </div>
          </Col>
        </Row>
      </Grid>
      <Grid style={{ width: "100%" }}>
        <Row>
          <Col xs={8} style={{ textAlign: "left", paddingLeft: 15 }}>
            <Whisper speaker={<Tooltip>Filters</Tooltip>} placement="topStart">
              <Icon icon="filter" />
            </Whisper>{" "}
            {accountFilter !== user.account_id ? (
              <Badge
                content={
                  loading_account_count ? "..." : account_count.account_count
                }
              >
                <Tag
                  className="button-shadow"
                  style={{
                    color: "white",
                    backgroundColor: "#38B1C1",
                  }}
                >
                  <Whisper
                    speaker={<Tooltip>Click to remove account filter</Tooltip>}
                    placement="topStart"
                  >
                    <span
                      style={{ cursor: "pointer", color: "white" }}
                      onClick={() => {
                        setAccountFilter(user.account_id);
                        setAccountFilterName("");
                      }}
                    >
                      <Icon icon="people-group" /> {accountFilterName}
                    </span>
                  </Whisper>
                </Tag>
              </Badge>
            ) : null}
          </Col>
          <Col xs={8}>
            {/*
        loading_count || loading_total
        ? <Loader speed="fast" /> 
        : selectedAll || selectedIds.length > 0
          ? <span>
            {"Selected "
            + (selectedAll 
              ? count.device_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : selectedIds.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            )
            + " of "
            + count.device_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            + " Devices"}
          </span>
          : <span>
            {"Showing " 
            + count.device_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") 
            + " of " 
            + total.device_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") 
            + " Devices"}
            </span>
            */}
          </Col>
          <Col xs={8} style={{ textAlign: "right", paddingRight: 15 }}>
            <ButtonToolbar>
              <Button
                size="sm"
                appearance="primary"
                onClick={() => setShowNewAccountModal(true)}
              >
                <Icon icon="plus-circle" /> New Sub-Account
              </Button>
              <Button
                size="sm"
                appearance="primary"
                onClick={() => {
                  //EXPORT
                  setShowExportModal(true);
                  load_export_data({
                    variables: {
                      //offset: (page - 1) * limit,
                      //limit,
                      get_children: true,
                      ...(accountFilter ? { id: accountFilter } : {}),
                      ...(globalSearch.length > 0
                        ? { name: globalSearch }
                        : {}),
                    },
                  });
                }}
              >
                <Icon icon="download" /> Export
              </Button>
            </ButtonToolbar>
            {/*<ButtonToolbar><Button size="sm" appearance="primary" onClick={()=>setShowImportModal(true)}><Icon icon="upload" /> Import</Button></ButtonToolbar>*/}
          </Col>
        </Row>
      </Grid>
      <Table
        data={accounts}
        className="button-shadow"
        style={{ borderRadius: "7px", margin: 10 }}
        onRowClick={(account) => {
          //setSelectedDevice(device);
        }}
        virtualized
        //height={window.innerHeight-80}
        autoHeight
        rowHeight={45}
        width={window.innerWidth - (menuExpanded ? 275 : 70)}
        loading={loading}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={(sortColumn, sortType) => {
          setSortColumn(sortColumn);
          setSortType(sortType);
        }}
      >
        <Table.Column width={45}>
          <Table.HeaderCell style={{ padding: 4 }}>
            <Checkbox
              checked={selectedAll}
              onChange={(value, checked) => {
                setSelectedAll(checked);
                if (checked === false) {
                  // Clear out checked IDs
                  setSelectedIds([]);
                } else {
                  setSelectedIds(accounts.map((d) => d.id));
                }
              }}
            />
          </Table.HeaderCell>
          <Table.Cell style={{ padding: 4 }}>
            {(account) => (
              <Checkbox
                key={"cb-" + account.id}
                checked={selectedIds.includes(account.id)}
                onChange={(value, checked) =>
                  handleCheckAccount(account.id, checked)
                }
              />
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={2} sortable>
          <Table.HeaderCell>Account Name</Table.HeaderCell>
          <Table.Cell style={{ textAlign: "left" }} dataKey="name">
            {(account) => (
              <Highlighter
                searchWords={[globalSearch]}
                textToHighlight={account.name}
                key={"hl-name-" + account.id}
                onClick={() => {
                  setSelectedAccount(account);
                  setShowDetailsModal(true);
                }}
                style={{
                  cursor: "pointer",
                }}
                key={"account-name-" + account.id}
              />
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={2}>
          <Table.HeaderCell>Account Path</Table.HeaderCell>
          <Table.Cell style={{ textAlign: "left" }}>
            {(account) => {
              let account_id_index = -1;

              //console.log({ path: account.account_path_array });

              return (
                <div key={"ap-" + account.id}>
                  {account.account_path_array.map((s, i) => {
                    //console.log({ s, i });
                    if (s && s.id === user.account_id) {
                      //console.log("matched");
                      account_id_index = i;
                    }
                    //console.log({ account_id_index });
                    if (account_id_index > -1 && i >= account_id_index) {
                      if (i >= account.account_path_array.length - 1) {
                        return (
                          <span
                            key={"ap1-" + account.id + "-" + i}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setAccountFilter(s.id);
                              setAccountFilterName(s.name);
                              setPage(1);
                            }}
                          >
                            <Whisper
                              speaker={<Tooltip>{s.name}</Tooltip>}
                              trigger={["hover"]}
                              placement="topEnd"
                            >
                              <Icon
                                icon={"people-group"}
                                style={{ margin: 3 }}
                                style={{ color: "#38B1C1", cursor: "pointer" }}
                              />
                            </Whisper>{" "}
                            {s.name}
                          </span>
                        );
                      } else {
                        return (
                          <span key={"api-" + s.id + "-" + i}>
                            <Whisper
                              speaker={<Tooltip>{s.name}</Tooltip>}
                              trigger={["hover"]}
                              placement="topEnd"
                            >
                              <Icon
                                icon={
                                  s.id === user.account_id
                                    ? "user"
                                    : "people-group"
                                }
                                style={{
                                  margin: 3,
                                  color: "#38B1C1",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setAccountFilter(s.id);
                                  setAccountFilterName(s.name);
                                }}
                              />
                            </Whisper>
                            {" / "}
                          </span>
                        );
                      }
                    } else {
                      // Cap account path
                      //console.log({ skipped: s });
                    }
                  })}
                </div>
              );
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Actions</Table.HeaderCell>
          <Table.Cell>
            {(account) => {
              return (
                <ButtonToolbar>
                  <Whisper
                    speaker={<Tooltip>Account Details</Tooltip>}
                    placement="top"
                  >
                    <Button
                      size="xs"
                      appearance="subtle"
                      color="blue"
                      onClick={() => {
                        setSelectedAccount(account);
                        setShowDetailsModal(true);
                      }}
                    >
                      <Icon icon="pencil" />
                    </Button>
                  </Whisper>
                  <Whisper
                    speaker={<Tooltip>Account Devices</Tooltip>}
                    placement="top"
                  >
                    <Button
                      size="xs"
                      appearance="subtle"
                      color="blue"
                      onClick={() => {
                        actions.setGlobalSearch("");
                        actions.setGlobalAccountFilter(account);
                        actions.setSelectedPage("devices");
                      }}
                    >
                      <Icon icon="list" />
                    </Button>
                  </Whisper>
                  <Whisper
                    speaker={<Tooltip>Transfer Devices</Tooltip>}
                    placement="top"
                  >
                    <Button
                      size="xs"
                      appearance="subtle"
                      color="blue"
                      onClick={() => {
                        setTransferAccount(account);
                        setShowTransferModal(true);
                      }}
                    >
                      <Icon icon="exchange" />
                    </Button>
                  </Whisper>
                  <Whisper
                    speaker={<Tooltip>Import Units</Tooltip>}
                    placement="top"
                  >
                    <Button
                      size="xs"
                      appearance="subtle"
                      color="blue"
                      onClick={() => {
                        setSelectedAccount(account);
                        setShowMassImportModal(true);
                      }}
                    >
                      <Icon icon="upload" />
                    </Button>
                  </Whisper>
                  <Whisper
                    speaker={<Tooltip>New Sub-Account</Tooltip>}
                    placement="top"
                  >
                    <Button
                      size="xs"
                      appearance="subtle"
                      color="blue"
                      onClick={() => {
                        setSelectedAccount(account);
                        setShowNewAccountModal(true);
                      }}
                    >
                      <Icon icon="plus-circle" />
                    </Button>
                  </Whisper>
                </ButtonToolbar>
              );
            }}
          </Table.Cell>
        </Table.Column>
      </Table>
      <Pagination
        pages={pages}
        activePage={page}
        maxButtons={window.innerWidth >= 1920 ? 20 : 10}
        first
        last
        boundaryLinks
        ellipsis
        onSelect={(page) => {
          //console.log({page, offset: page*limit});
          setPage(page);
          //setOffset(page*limit);
          refetch();
          setSelectedAll(false);
          setSelectedIds([]);
        }}
        style={{
          margin: "auto",
          marginTop: 0,
          marginBottom: 0,
        }}
      />
      <Details
        show={showDetailsModal}
        setShow={setShowDetailsModal}
        account={selectedAccount}
        refetch={refetch}
        hardId={id}
      />
      <Transfer
        show={showTransferModal}
        setShow={setShowTransferModal}
        account={transferAccount}
      />
      <Import
        show={showMassImportModal}
        setShow={setShowMassImportModal}
        account={selectedAccount}
      />
      <New
        show={showNewAccountModal}
        setShow={setShowNewAccountModal}
        account={selectedAccount}
      />
      <Modal show={showExportModal}>
        <Modal.Header onHide={() => setShowExportModal(false)}>
          <Modal.Title>Export Accounts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading_export_data ? (
            <Loader content="Preparing export data..." size="md" />
          ) : export_data ? (
            <CSVLink
              filename={"Accounts_Export.csv"}
              data={
                export_data && export_data.accounts
                  ? export_data.accounts.map((account) => {
                      return {
                        "Account Name": account.name,
                        "Account Path": account.account_path_array
                          .map((a) => a.name)
                          .join(" / "),
                      };
                    })
                  : []
              }
              onClick={() => setShowExportModal(false)}
            >
              DOWNLOAD
            </CSVLink>
          ) : (
            "Error preparing export data"
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};
