import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Container,
  Form,
  FormGroup,
  Panel,
  InputGroup,
  Input,
  Icon,
  Modal,
  ButtonToolbar,
  Message,
  Alert,
  Loader,
} from "rsuite";
import sha1 from "sha1";
import jscookie from "js-cookie";
import config from "../config";

// GQL
import {
  useLazyQuery,
  useMutation,
  useApolloClient,
  useQuery,
} from "@apollo/react-hooks";
import GQL from "./graphql";

import { useApp } from "../AppStore";

export default (props) => {
  const [{ loginError }, actions] = useApp();
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const client = useApolloClient();

  // GQL
  const {
    data: valid_password_reset_token,
    loading: loading_valid_password_reset_token,
    refetch: refetch_valid_password_reset_token,
  } = useQuery(GQL.Queries.valid_password_reset_token, {
    variables: {
      token:
        props && props.match && props.match.params && props.match.params.token
          ? props.match.params.token
          : "",
    },
  });
  const [reset_password_and_login] = useMutation(
    GQL.Mutations.reset_password_and_login
  );

  const doLogin = () => {
    // TODO
    reset_password_and_login({
      variables: {
        token: props.match.params.token,
        password: sha1(password),
      },
    }).then((res) => {
      jscookie.set(
        config.session_cookie_name,
        res.data.reset_password_and_login.token
      );
      actions.setUser(res.data.reset_password_and_login.user);
      actions.setLoggedIn(true);
      window.location = "/";
    });
  };

  return (
    <Container
      style={{
        width: "100%",
        paddingTop: "64px",
        radius: "5px",
        placeItems: "center",
      }}
    >
      <img
        src="/images/m2m-shadow.png"
        width="150px"
        alt="logo"
        style={{ position: "relative", top: "75px", zIndex: 999999 }}
      />
      <Panel
        shaded
        style={{
          width: Math.min(window.innerWidth, 480) + "px",
          background: "#FFFFFF",
          backdropFilter: "blur(5px)",
          margin: "auto",
          color: "#20396D",
          paddingTop: "50px",
        }}
      >
        <Container style={{ padding: "25px", paddingTop: 0, fontSize: "24px" }}>
          <big>Reset Password</big>
        </Container>
        <Container>
          {loading_valid_password_reset_token ? (
            <Loader content="Verifying token..." />
          ) : valid_password_reset_token &&
            valid_password_reset_token.valid_password_reset_token ? (
            <Form style={{ width: "100%" }}>
              <FormGroup>
                <InputGroup style={{ width: "100%" }}>
                  <InputGroup.Addon>
                    <Icon icon="key" />
                  </InputGroup.Addon>
                  <Input
                    //type="password"
                    placeholder="New Password"
                    value={password}
                    onChange={setPassword}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup style={{ width: "100%" }}>
                  <InputGroup.Addon>
                    <Icon icon="key" />
                  </InputGroup.Addon>
                  <Input
                    //type="password"
                    placeholder="Confirm Password"
                    value={confirm}
                    onChange={setConfirm}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13) {
                        //doLogin();
                        //actions.login({ username, password, client });
                      }
                    }}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <ButtonGroup style={{ width: "100%" }}>
                  <Button
                    disabled={!(password.length > 0 && password == confirm)}
                    className="button-shadow"
                    appearance="primary"
                    style={{ width: "100%" }}
                    onClick={() => {
                      doLogin();
                      //actions.login({ username, password, client });
                    }}
                    size="lg"
                  >
                    <Icon icon="sign-in" /> Submit
                  </Button>
                </ButtonGroup>
              </FormGroup>
            </Form>
          ) : (
            <Message
              type="error"
              description="Invalid or Expired Token"
              showIcon
            />
          )}
        </Container>
        {error && error.length > 0 ? (
          <Form>
            <FormGroup>
              <Message
                type="error"
                title="Error"
                showIcon
                description={error}
              />
            </FormGroup>
          </Form>
        ) : null}
      </Panel>
    </Container>
  );
};
