import React, { useState, useEffect } from "react";
import {
  Container,
  Modal,
  Button,
  Table,
  Grid,
  Row,
  Col,
  Icon,
  Input,
  InputGroup,
  Panel,
  Form,
  FormGroup,
  ButtonGroup,
  ButtonToolbar,
  Message,
  Alert,
  Nav,
  Whisper,
  Tooltip,
  SelectPicker,
} from "rsuite";
import {
  useQuery,
  useMutation,
  useLazyQuery,
  useApolloClient,
} from "@apollo/react-hooks";
import CSVReader from "react-csv-reader";
import sha1 from "sha1";

import { useApp } from "../../../AppStore";
import Devices from "../../devices";
import MassCommand from "./masscommand";
import GQL from "../graphql";

export default (props) => {
  const { show, setShow, account, refetch, hardId } = props;
  const usersTableHeight = window.innerHeight - 450;
  /** Global Variables */
  const [{ user }, actions] = useApp();
  const [name, setName] = useState(""); //useState(account && account.name ? account.name : "");
  const [selectedUser, setSelectedUser] = useState(null);
  const [userName, setUserName] = useState("");
  const [userUsername, setUserUsername] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [creatingUser, setCreatingUser] = useState(false);
  const [newUserError, setNewUserError] = useState("");
  const [showDeleteUserConfirmation, setShowDeleteUserConfirmation] =
    useState(false);
  const [activeTab, setActiveTab] = useState("devices");
  const [lot_management, setLot_management] = useState(null);

  const apolloClient = useApolloClient();
  /*
  const {
    data: users,
    loading: loading_users,
    refetch: refetch_users,
  } = useQuery(GQL.Queries.users, {
    variables: {
      ...(account && account.id ? { account_id: account.id } : {}),
    },
  });*/

  //console.log({ hardId });

  const [refetch_users, { loading: loading_users, data: users }] = useLazyQuery(
    GQL.Queries.users,
    {
      fetchPolicy: "network-only",
    }
  );
  const { data, loading } = useQuery(GQL.Queries.account, {
    variables: {
      ...(hardId
        ? { id: hardId }
        : account && account.id
        ? { id: account.id }
        : {}),
    },
    onCompleted: (data) => {
      //console.log({ data });
      if (data && data.account && data.account.name && data.account.id) {
        setName(data.account.name);
        setLot_management(data.account.lot_management || null);
        //console.log("grabbing users");
        refetch_users({ variables: { account_id: data.account.id } });
      }
    },
  });
  const [update_account] = useMutation(GQL.Mutations.update_account);
  const [create_user] = useMutation(GQL.Mutations.create_user);
  const [update_user] = useMutation(GQL.Mutations.update_user);
  const [delete_user] = useMutation(GQL.Mutations.delete_user);

  //console.log({ data });

  /*useEffect(() => {
    if (account && account.name) {
      setName(account.name);
    }
  }, [account]);  */
  //console.log({ account });

  const renderContent = () => {
    if (activeTab === "devices") {
      return (
        <Devices
          getChildren={false}
          account_filter_id={
            hardId ? parseInt(hardId) : account && account.id ? account.id : 0
          }
        />
      );
    } else if (activeTab === "users") {
      return (
        <>
          <ButtonGroup>
            <Button
              size="sm"
              appearance="primary"
              onClick={() => {
                setSelectedUser(null);
                setShowUserDetails(true);
              }}
            >
              <Icon icon="plus-circle" /> New User
            </Button>
          </ButtonGroup>
          <Table
            data={users && users.users && !loading_users ? users.users : []}
            height={usersTableHeight}
            //virtualized
            //autoHeight
            loading={loading_users}
          >
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.Cell dataKey="name" />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Username</Table.HeaderCell>
              <Table.Cell dataKey="username" />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.Cell dataKey="email" />
            </Table.Column>
            <Table.Column width={100}>
              <Table.HeaderCell>Actions</Table.HeaderCell>
              <Table.Cell>
                {(user) => {
                  return (
                    <ButtonGroup>
                      <Button
                        size="xs"
                        appearance="subtle"
                        color="yellow"
                        onClick={() => {
                          setSelectedUser(user);
                          setUserName(user.name);
                          setUserUsername(user.username);
                          setUserEmail(user.email);
                          setShowUserDetails(true);
                        }}
                      >
                        <Icon icon="pencil" />
                      </Button>
                      <Button
                        size="xs"
                        appearance="subtle"
                        color="red"
                        onClick={() => {
                          //DELETE USER
                          setSelectedUser(user);
                          setShowDeleteUserConfirmation(true);
                        }}
                      >
                        <Icon icon="trash" />
                      </Button>
                    </ButtonGroup>
                  );
                }}
              </Table.Cell>
            </Table.Column>
          </Table>
          <Modal
            show={showDeleteUserConfirmation}
            onHide={() => setShowDeleteUserConfirmation(false)}
          >
            <Modal.Header>
              <Modal.Title>Delete User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Message
                type="warning"
                showIcon
                description={
                  "Are you sure you want to delete user (" +
                  (selectedUser && selectedUser.name ? selectedUser.name : "") +
                  ")? This CANNOT be undone!"
                }
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                color="red"
                onClick={() => {
                  if (selectedUser && selectedUser.id) {
                    delete_user({ variables: { id: selectedUser.id } }).then(
                      (res) => {
                        setShowDeleteUserConfirmation(false);
                        setSelectedUser(null);
                        Alert.success("User deleted.");
                        refetch_users({
                          variables: {
                            ...(hardId
                              ? { account_id: hardId }
                              : account && account.id
                              ? { account_id: account.id }
                              : {}),
                          },
                        });
                      }
                    );
                  } else {
                    setShowDeleteUserConfirmation(false);
                    setSelectedUser(null);
                  }
                }}
              >
                <Icon icon="trash" /> Yes
              </Button>
              <Button
                appearance="subtle"
                color="yellow"
                onClick={() => {
                  setShowDeleteUserConfirmation(false);
                  setSelectedUser(null);
                }}
              >
                <Icon icon="ban" /> No
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={showUserDetails}
            onHide={() => {
              setShowUserDetails(false);
            }}
          >
            <Modal.Header>
              <Modal.Title>
                {selectedUser == null ? "New " : "Edit "}User
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <FormGroup>
                  <InputGroup style={{ width: "100%" }}>
                    <InputGroup.Addon>
                      <Icon icon="tag" />
                    </InputGroup.Addon>
                    <Input
                      value={userName}
                      onChange={setUserName}
                      placeholder="Name"
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup style={{ width: "100%" }}>
                    <InputGroup.Addon>
                      <Icon icon="user" />
                    </InputGroup.Addon>
                    <Input
                      value={userUsername}
                      onChange={setUserUsername}
                      placeholder="Username"
                      disabled={selectedUser}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup style={{ width: "100%" }}>
                    <InputGroup.Addon>
                      <Icon icon="key" />
                    </InputGroup.Addon>
                    <Input
                      value={userPassword}
                      onChange={setUserPassword}
                      placeholder="password"
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup style={{ width: "100%" }}>
                    <InputGroup.Addon>
                      <Icon icon="at" />
                    </InputGroup.Addon>
                    <Input
                      value={userEmail}
                      onChange={setUserEmail}
                      placeholder="Email"
                    />
                  </InputGroup>
                </FormGroup>
                {newUserError && newUserError.length > 0 ? (
                  <FormGroup>
                    <Message showIcon type="error" description={newUserError} />
                  </FormGroup>
                ) : null}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                disabled={creatingUser}
                appearance="primary"
                onClick={() => {
                  if (selectedUser) {
                    update_user({
                      variables: {
                        id: selectedUser.id,
                        name: userName,
                        email: userEmail,
                        ...(userPassword.length > 0
                          ? { password: sha1(userPassword) }
                          : {}),
                      },
                    })
                      .then((res) => {
                        if (
                          "data" in res &&
                          "update_user" in res.data &&
                          res.data.update_user
                        ) {
                          setNewUserError("");
                          setShowUserDetails(false);
                          setSelectedUser(null);
                          setUserName("");
                          setUserEmail("");
                          setUserUsername("");
                          setUserPassword("");
                          refetch_users({
                            variables: {
                              ...(hardId
                                ? { account_id: hardId }
                                : account && account.id
                                ? { account_id: account.id }
                                : {}),
                            },
                          });
                        } else {
                          setNewUserError("Error updating user.");
                        }
                      })
                      .catch((err) => {
                        setNewUserError("Error updating user.");
                      });
                  } else {
                    // Check for duplicate usernames
                    //console.log("Checking for dupes");
                    setCreatingUser(true);

                    apolloClient
                      .query({
                        query: GQL.Queries.user_by_username,
                        variables: {
                          username: userUsername,
                        },
                      })
                      .then((res) => {
                        //console.log({ res });
                        if (
                          res &&
                          res.data &&
                          res.data.user_by_username &&
                          res.data.user_by_username.id
                        ) {
                          // Duplicate username
                          setNewUserError("Username already exists!");
                          setCreatingUser(false);
                        } else {
                          //console.log("Creating user");
                          create_user({
                            variables: {
                              account_id: account.id,
                              name: userName,
                              username: userUsername,
                              password: sha1(userPassword),
                              email: userEmail,
                            },
                          }).then((res) => {
                            if (res.data.create_user === true) {
                              setNewUserError("");
                              setShowUserDetails(false);
                              setSelectedUser(null);
                              setUserName("");
                              setUserEmail("");
                              setUserUsername("");
                              setUserPassword("");
                              refetch_users({
                                variables: {
                                  ...(hardId
                                    ? { account_id: hardId }
                                    : account && account.id
                                    ? { account_id: account.id }
                                    : {}),
                                },
                              });
                              setCreatingUser(false);
                            } else {
                              setNewUserError("Error creating user!");
                              setCreatingUser(false);
                            }
                          });
                        }
                      });
                  }
                }}
              >
                <Icon icon="disk" />{" "}
                {selectedUser == null
                  ? creatingUser
                    ? "Creating..."
                    : "Create"
                  : "Save"}
              </Button>
              <Button
                appearance="subtle"
                color="orange"
                onClick={() => {
                  setShowUserDetails(false);
                  setSelectedUser(null);
                  setUserName("");
                  setUserEmail("");
                  setUserUsername("");
                  setUserPassword("");
                }}
              >
                <Icon icon="ban" /> Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    } else if (activeTab === "masscommands") {
      return <MassCommand id={account && account.id ? account.id : null} />;
    }
  };

  return (
    <Modal
      show={show}
      //size="md"
      full
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Header>
        <Modal.Title>Account Details{name ? " for " + name : ""}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <FormGroup>
            <InputGroup style={{ width: "100%" }}>
              <InputGroup.Addon>
                <Whisper speaker={<Tooltip>Account Name</Tooltip>}>
                  <Icon icon="tag" />
                </Whisper>
              </InputGroup.Addon>
              <Input value={name} onChange={setName} />
              {data &&
              data.account &&
              data.account.name &&
              name !== data.account.name ? (
                <Whisper
                  speaker={<Tooltip>Unsaved Changes!</Tooltip>}
                  placement="topStart"
                >
                  <InputGroup.Addon>
                    <Icon icon="exclamation" style={{ color: "orange" }} />
                  </InputGroup.Addon>
                </Whisper>
              ) : null}
              {user &&
                (user.account_id == 1 ||
                  (user.account && user.account.lot_management != null)) && (
                  <InputGroup.Addon>
                    <Whisper speaker={<Tooltip>Lot Management</Tooltip>}>
                      <Icon icon="map-marker" />
                    </Whisper>
                  </InputGroup.Addon>
                )}
              {user &&
                (user.account_id == 1 ||
                  (user.account && user.account.lot_management != null)) && (
                  <SelectPicker
                    searchable={false}
                    cleanable={false}
                    data={[{ label: "Disabled", value: null }].concat(
                      user.account.lot_management == "admin"
                        ? [
                            {
                              label: "Allowed to grant Lot Managment",
                              value: "grant",
                            },
                            { label: "Enable Lot Management", value: "access" },
                          ]
                        : [{ label: "Enable Lot Management", value: "access" }]
                    )}
                    value={lot_management}
                    onChange={setLot_management}
                    disabled={account && user && account.id === user.account_id}
                  />
                )}
            </InputGroup>
          </FormGroup>
          <Panel
            header={
              <Grid fluid>
                <Row>
                  <Col xs={24}>
                    <Nav
                      activeKey={activeTab}
                      onSelect={setActiveTab}
                      appearance="subtle"
                    >
                      <Nav.Item
                        eventKey="devices"
                        icon={<Icon icon="map-marker" />}
                      >
                        Devices
                      </Nav.Item>
                      <Nav.Item eventKey="users" icon={<Icon icon="users" />}>
                        Users
                      </Nav.Item>
                      <Nav.Item
                        eventKey="masscommands"
                        icon={<Icon icon="terminal-line" />}
                      >
                        Mass Commands
                      </Nav.Item>
                    </Nav>
                  </Col>
                </Row>
                {/*
                <Row>
                  <Col xs={12}>
                    {account && account.name ? account.name + " " : ""}Users
                  </Col>
                  <Col xs={12} style={{ textAlign: "right" }}>
                    <ButtonGroup>
                      <Button
                        size="sm"
                        appearance="primary"
                        onClick={() => {
                          setSelectedUser(null);
                          setShowUserDetails(true);
                        }}
                      >
                        <Icon icon="plus-circle" /> New User
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
                      */}
              </Grid>
            }
            className="button-shadow"
            style={{ border: "1px solid #efefef" }}
          >
            {renderContent()}
          </Panel>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={
            data &&
            data.account &&
            data.account.name &&
            data.account.name === name &&
            data.account.lot_management === lot_management
          }
          color="green"
          onClick={() => {
            update_account({
              variables: {
                id: account.id,
                name: name.trim(),
                lot_management,
              },
            })
              .then((res) => {
                if (res && res.data && res.data.update_account === true) {
                  refetch();
                  setShow(false);
                }
              })
              .catch((err) => console.error({ err }));
          }}
        >
          <Icon icon="save" /> Save
        </Button>
        <Button
          appearance="subtle"
          onClick={() => setShow(false)}
          color="orange"
        >
          <Icon icon="close-circle" /> Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
