import gql from "graphql-tag";

export default {
  Queries: {
    consumer: gql`
      query consumer($id: Int!) {
        consumer(id: $id) {
          id
          device {
            name
            imei
          }
          vehicle {
            id
            year
            make
            model
            vin
          }
          first_name
          last_name
          address
          city
          state
          zip
          country
          email
          phone
          created_at
        }
      }
    `,
    consumers: gql`
      query consumers($search: String, $limit: Int, $offset: Int) {
        consumers(search: $search, limit: $limit, offset: $offset) {
          id
          device {
            name
            imei
          }
          vehicle {
            id
            year
            make
            model
            vin
          }
          first_name
          last_name
          address
          city
          state
          zip
          country
          email
          phone
          account_path_array {
            id
            name
          }
          created_at
        }
      }
    `,
    consumers_count: gql`
      query consumers_count($search: String) {
        consumers_count(search: $search)
      }
    `,
  },
  Mutations: {
    update_consumer: gql`
      mutation update_consumer(
        $id: Int!
        $first_name: String
        $last_name: String
        $email: String
        $phone: String
        $address: String
        $address_2: String
        $city: String
        $state: String
        $zip: String
      ) {
        update_consumer(
          id: $id
          first_name: $first_name
          last_name: $last_name
          email: $email
          phone: $phone
          address: $address
          address_2: $address_2
          city: $city
          state: $state
          zip: $zip
        )
      }
    `,
    update_vehicle: gql`
      mutation update_vehicle(
        $id: Int!
        $year: Int
        $make: String
        $model: String
        $vin: String
      ) {
        update_vehicle(
          id: $id
          year: $year
          make: $make
          model: $model
          vin: $vin
        )
      }
    `,
  },
};
