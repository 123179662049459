import gql from "graphql-tag";

export default {
  Queries: {
    accounts: gql`
      query accounts(
        $id: Int
        $offset: Int
        $limit: Int
        $get_children: Boolean
        $name: String
        $sortType: String
        $sortField: String
      ) {
        accounts(
          id: $id
          offset: $offset
          limit: $limit
          get_children: $get_children
          name: $name
          sortType: $sortType
          sortField: $sortField
        ) {
          id
          name
        }
      }
    `,
    device_report_count: gql`
      query device_report_count($device_id: Int!) {
        device_report_count(device_id: $device_id)
      }
    `,
    allChildAccountIds: gql`
      query allChildAccountIds {
        allChildAccountIds
      }
    `,
    device_lastreport: gql`
      query devices($id: Int!) {
        devices(id: $id) {
          id
          last_report {
            location {
              latitude
              longitude
            }
            location_accuracy
            address
            send_time
            battery_level
            event_type_id
          }
        }
      }
    `,
    device: gql`
      query device($id: Int!) {
        device(id: $id) {
          id
          name
          uuid
          account_full_path
          enrolled_device {
            account {
              name
            }
          }
          default_device_mode_id
          attached_to
          imei
          sim_phone_number
          sim_provider_id
          sim_provider {
            id
            name
          }
          manufacturer
          model
          device_type_id
          device_mode_id
          device_mode {
            name
          }
          enrolled_date
          report_count
          last_report
          consumers {
            first_name
            last_name
            email
            phone
            address
            address_2
            city
            state
            zip
            created_at
            vehicle {
              vin
              make
              model
              year
            }
          }
        }
      }
    `,
    reported_count: gql`
      query reported_count($start: String) {
        reported_count(start: $start)
      }
    `,
    devices: gql`
      query devices(
        $account_id: Int
        $get_children: Boolean
        $account_ids: [Int!]
        $limit: Int
        $offset: Int
        $imei: String
        $sim_phone_number: String
        $name: String
        $account_name: String
        $sortType: String
        $sortField: String
      ) {
        devices(
          account_id: $account_id
          get_children: $get_children
          account_ids: $account_ids
          limit: $limit
          offset: $offset
          imei: $imei
          sim_phone_number: $sim_phone_number
          name: $name
          account_name: $account_name
          sortType: $sortType
          sortField: $sortField
        ) {
          id
          name
          attached_to
          imei
          sim_phone_number
          sim_provider_id
          sim_provider {
            id
            name
          }
          enrolled_date
          last_report_date
          last_report
          device_type_id
          device_mode_id
          device_mode {
            name
          }
          account_path
          account_full_path
          account_path_array {
            id
            name
          }
          registered
        }
      }
    `,
    devices_for_export: gql`
      query devices(
        $account_id: Int
        $get_children: Boolean
        $account_ids: [Int!]
        $limit: Int
        $offset: Int
        $imei: String
        $sim_phone_number: String
        $name: String
        $account_name: String
        $sortType: String
        $sortField: String
      ) {
        devices(
          account_id: $account_id
          get_children: $get_children
          account_ids: $account_ids
          limit: $limit
          offset: $offset
          imei: $imei
          sim_phone_number: $sim_phone_number
          name: $name
          account_name: $account_name
          sortType: $sortType
          sortField: $sortField
        ) {
          id
          name
          attached_to
          imei
          sim_phone_number
          sim_provider_id
          sim_provider {
            id
            name
          }
          enrolled_date
          last_report_date
          last_report
          device_type_id
          device_mode_id
          device_mode {
            name
          }
          account_path
          account_full_path
          account_path_array {
            id
            name
          }
          registered
          report_count
        }
      }
    `,
    devices_with_last_report: gql`
      {
        devices {
          id
          name
          attached_to
          imei
          device_mode_id
          device_type_id
          device_mode {
            name
          }
          last_report {
            location {
              latitude
              longitude
            }
            location_accuracy
            address
            send_time
            battery_level
            event_type_id
          }
        }
      }
    `,
    push_command_count: gql`
      query push_command_count($device_id: Int) {
        push_command_count(device_id: $device_id)
      }
    `,
    push_commands: gql`
      query push_commands($device_id: Int, $limit: Int, $offset: Int) {
        push_commands(
          device_id: $device_id
          limit: $limit
          offset: $offset
          sort_desc: true
        ) {
          id
          command_id
          command_type
          command
          payload
          sent
          created_at
        }
      }
    `,
    device_count: gql`
      query device_count(
        $account_id: Int
        $get_children: Boolean
        $imei: String
        $sim_phone_number: String
        $name: String
      ) {
        device_count(
          account_id: $account_id
          get_children: $get_children
          imei: $imei
          sim_phone_number: $sim_phone_number
          name: $name
        )
      }
    `,
    device_reports: gql`
      query device_reports(
        $device_id: Int!
        $start: String
        $end: String
        $offset: Int
        $limit: Int
      ) {
        device_reports(
          device_id: $device_id
          start: $start
          end: $end
          offset: $offset
          limit: $limit
        ) {
          id
          location {
            latitude
            longitude
          }
          address
          send_time
          signal_strength
          gps_time
          event_type_id
          event_type {
            name
            color
          }
          location_accuracy
          payload
          battery_level
          created_at
        }
      }
    `,
    geofences: gql`
      query geofences($device_id: Int) {
        device_geofences(device_id: $device_id) {
          id
          name
          trigger
          radius
          latitude
          longitude
          address
        }
      }
    `,
    device_modes: gql`
      query device_modes($device_type_id: Int) {
        device_modes(device_type_id: $device_type_id) {
          id
          name
          command
        }
      }
    `,
    device_types: gql`
      {
        device_types {
          id
          name
        }
      }
    `,
    unsent_push_commands: gql`
      query push_commands($device_id: Int) {
        push_commands(device_id: $device_id, sent: false) {
          id
        }
      }
    `,
    sim_providers: gql`
      {
        sim_providers {
          id
          name
        }
      }
    `,
    repo_tokens: gql`
      query repo_tokens($device_id: Int) {
        repo_tokens(device_id: $device_id) {
          id
          email
          token
          active
          expires_at
        }
      }
    `,
  },
  Mutations: {
    transfer_devices_using_ids: gql`
      mutation transfer_devices_using_ids($ids: [Int!]!, $account_id: Int!) {
        transfer_devices_using_ids(ids: $ids, account_id: $account_id)
      }
    `,
    mass_upload: gql`
      mutation mass_upload(
        $devices: [[String!]!]
        $device_type_id: Int!
        $sim_provider_id: Int
      ) {
        mass_upload(
          devices: $devices
          device_type_id: $device_type_id
          sim_provider_id: $sim_provider_id
        )
      }
    `,
    create_push_command: gql`
      mutation create_push_command(
        $device_id: Int!
        $command_id: Int!
        $command_type: String!
        $command: String!
        $payload: String!
      ) {
        create_push_command(
          device_id: $device_id
          command_id: $command_id
          command_type: $command_type
          command: $command
          payload: $payload
        ) {
          id
        }
      }
    `,
    update_device: gql`
      mutation update_device(
        $id: Int!
        $name: String
        $attached_to: String
        $device_mode_id: Int
        $sim_provider_id: Int
        $device_type_id: Int
        $manufacturer: String
        $model: String
        $default_device_mode_id: Int
      ) {
        update_device(
          id: $id
          name: $name
          attached_to: $attached_to
          device_mode_id: $device_mode_id
          sim_provider_id: $sim_provider_id
          device_type_id: $device_type_id
          manufacturer: $manufacturer
          model: $model
          default_device_mode_id: $default_device_mode_id
        )
      }
    `,
    create_repo_token: gql`
      mutation create_repo_token(
        $email: String!
        $device_id: Int!
        $expires_at: String
      ) {
        create_repo_token(
          email: $email
          device_id: $device_id
          expires_at: $expires_at
        )
      }
    `,
    update_repo_token: gql`
      mutation update_repo_token($id: Int!, $expires_at: String) {
        update_repo_token(id: $id, expires_at: $expires_at)
      }
    `,
    disable_repo_token: gql`
      mutation disable_repo_token($id: Int!) {
        disable_repo_token(id: $id)
      }
    `,
    enable_repo_token: gql`
      mutation enable_repo_token($id: Int!) {
        enable_repo_token(id: $id)
      }
    `,
    delete_repo_token: gql`
      mutation delete_repo_token($id: Int!) {
        delete_repo_token(id: $id)
      }
    `,
  },
};
