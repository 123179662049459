import React, { useState, useEffect } from "react";
import { Avatar, Container, Icon, Grid, Row, Col } from "rsuite";

import { useApp } from "../AppStore";

export default (props) => {
  const [
    {
      loggedIn,
      menuExpanded,
      globalFilters,
      globalUpdates,
      user,
      selectedPage: globalSelectedPage,
      globalSearch,
    },
    actions,
  ] = useApp();

  return (
    <Container style={{ padding: 15, paddingLeft: 10, paddingBottom: 0 }}>
      <Grid
        fluid
        style={{
          paddingBottom: 15,
          width: "100%",
          borderBottom: "1px solid lightgray",
        }}
      >
        <Row>
          <Col xs={4}>
            <Avatar circle={true} style={{ backgroundColor: "#38b1c1" }}>
              <Icon icon="user" />
            </Avatar>
          </Col>
          <Col xs={20}>
            <Container style={{ paddingLeft: 5 }}>
              <big>
                <strong>{user && user.name ? user.name : "..."}</strong>
              </big>
            </Container>
            <Container style={{ paddingLeft: 5 }}>
              <small style={{ color: "#38b1c1" }}>
                {user && user.email ? user.email : "..."}
              </small>
            </Container>
          </Col>
        </Row>
      </Grid>
    </Container>
  );
};
