import React, { useState, useEffect } from "react";
import {
  Alert,
  Container,
  Modal,
  Button,
  Table,
  Grid,
  Row,
  Col,
  Icon,
  Input,
  InputGroup,
  Form,
  FormGroup,
  Message,
  Notification,
} from "rsuite";
import { useQuery, useMutation, useApolloClient } from "@apollo/react-hooks";
import CSVReader from "react-csv-reader";
import sha1 from "sha1";
import GQL from "./graphql";
import { set } from "js-cookie";
import { useApp } from "../../AppStore";
import { reduce } from "lodash";

export default (props) => {
  const { show, setShow, account, refetch } = props;
  /** Global Variables */
  const [{ user }, actions] = useApp();

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const apolloClient = useApolloClient();
  const [create_account] = useMutation(GQL.Mutations.create_account);
  const [create_user] = useMutation(GQL.Mutations.create_user);
  const [update_account] = useMutation(GQL.Mutations.update_account);

  //console.log({ account });

  return (
    <Modal
      show={show}
      size="md"
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Header>
        <Modal.Title>
          New Sub-Account Details{" "}
          {account && account.name ? "for " + account.name : ""}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form fluid>
          <FormGroup>
            <InputGroup style={{ width: "100%" }}>
              <InputGroup.Addon>
                <Icon icon="tag" />
              </InputGroup.Addon>
              <Input
                value={name}
                onChange={setName}
                placeholder="Account Name"
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup style={{ width: "100%" }}>
              <InputGroup.Addon>
                <Icon icon="user" />
              </InputGroup.Addon>
              <Input
                value={username}
                onChange={setUsername}
                placeholder="Account Main Username"
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup style={{ width: "100%" }}>
              <InputGroup.Addon>
                <Icon icon="lock" />
              </InputGroup.Addon>
              <Input
                value={password}
                onChange={setPassword}
                placeholder="*Password not hidden"
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup style={{ width: "100%" }}>
              <InputGroup.Addon>
                <Icon icon="at" />
              </InputGroup.Addon>
              <Input
                value={email}
                onChange={setEmail}
                placeholder="Account Main Email"
              />
            </InputGroup>
          </FormGroup>
          {error && error.length > 0 ? (
            <Message showIcon type="error" description={error} />
          ) : null}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          color="green"
          onClick={() => {
            // Check username for duplicates
            apolloClient
              .query({
                query: GQL.Queries.user_by_username,
                variables: {
                  username,
                },
              })
              .then((res) => {
                if (
                  res.data &&
                  res.data.user_by_username &&
                  res.data.user_by_username.id
                ) {
                  setError("Username already exists!");
                } else {
                  create_account({
                    variables: {
                      ...(account && account.id
                        ? { parent_account_id: account.id }
                        : {}) /*{ parent_account_id: user.account_id }*/,
                      name,
                      username,
                      password: sha1(password),
                      email,
                    },
                  }).then((res) => {
                    if (res.data.create_account === true) {
                      setError("");
                      setName("");
                      setEmail("");
                      setPassword("");
                      setUsername("");
                      setShow(false);
                      Alert.success("New sub-account has been created.");
                    } else {
                      setError("Error creating account!");
                    }
                  });
                }
              });
          }}
        >
          <Icon icon="plus-circle" /> Create
        </Button>
        <Button
          appearance="subtle"
          onClick={() => setShow(false)}
          color="orange"
        >
          <Icon icon="close-circle" /> Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
