import React, { useCallback, useState, useEffect, useRef } from "react";
import _ from "lodash";
import { CSVLink } from "react-csv";
import {
  Container,
  Checkbox,
  Icon,
  Table,
  Panel,
  InputGroup,
  Whisper,
  Tooltip,
  Input,
  Loader,
  Pagination,
  Grid,
  Row,
  Col,
  Tag,
  Badge,
  ButtonToolbar,
  Button,
  Modal,
  Progress,
  SelectPicker,
} from "rsuite";
import Highlighter from "react-highlight-words";
import CSVReader from "react-csv-reader";
import ArrayToCsv from "convert-array-to-csv";
import FileSaver from "file-saver";
import moment from "moment";

import { useApp } from "../../AppStore";
import tools from "../../lib/tools";

import Consumer from "./consumer";

// GQL
import {
  useQuery,
  useLazyQuery,
  useApolloClient,
  useMutation,
} from "@apollo/react-hooks";
import GQL from "./graphql";

export default (props) => {
  const limit = parseInt((window.innerHeight - 145) / 45);
  const [
    { globalFilters, menuExpanded, globalSearch, user, globalAccountFilter },
    actions,
  ] = useApp();

  const [showConsumer, setShowConsumer] = useState(false);
  const [selectedConsumer, setSelectedConsumer] = useState(null);
  const [consumers, setConsumers] = useState([]);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);

  const { data, refetch, loading } = useQuery(GQL.Queries.consumers, {
    variables: {
      ...(globalSearch && globalSearch.length > 0
        ? { search: globalSearch }
        : {}),
      limit,
      offset,
    },
  });
  const {
    data: count,
    refetch: refetch_count,
    loading: loading_count,
  } = useQuery(GQL.Queries.consumers_count, {
    variables: {
      ...(globalSearch && globalSearch.length > 0
        ? { search: globalSearch }
        : {}),
    },
    onCompleted: (data) => {
      //console.log({data, limit});
      const pages = Math.ceil(data.consumers_count / limit);
      setPages(pages);
      if (page > pages) {
        setPage(pages);
        setOffset(pages * limit);
      }
    },
  });

  //console.log({ data });
  //console.log({ count });

  useEffect(() => {
    setPage(1);
    setOffset(0);
  }, [globalSearch]);

  return (
    <Container
      style={{
        paddingTop: 0,
        textAlign: "center",
      }}
    >
      <Table
        data={data && data.consumers ? data.consumers : []}
        className="button-shadow"
        style={{ borderRadius: "7px", margin: 10 }}
        onRowClick={(device) => {
          //setSelectedDevice(device);
        }}
        virtualized
        //height={window.innerHeight-80}
        autoHeight
        rowHeight={45}
        width={window.innerWidth - (menuExpanded ? 275 : 70)}
        loading={loading}
        affixHorizontalScrollbar
      >
        <Table.Column width={200} fixed="left" key="col-name">
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.Cell style={{ textAlign: "center" }}>
            {(consumer) => {
              const name = consumer.first_name + " " + consumer.last_name;
              return (
                <Whisper speaker={<Tooltip>{name}</Tooltip>} placement="top">
                  <Highlighter
                    searchWords={[globalSearch]}
                    textToHighlight={name}
                    key={"hl-name-" + consumer.id}
                    onClick={() => {
                      setSelectedConsumer(consumer);
                      setShowConsumer(true);
                      //setSelectedDevice(device);
                      //setShowDetails(true);
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </Whisper>
              );
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={250} key="col-name">
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.Cell style={{ textAlign: "center" }}>
            {(consumer) => (
              <Whisper
                speaker={<Tooltip>{consumer.email}</Tooltip>}
                placement="top"
              >
                <Highlighter
                  searchWords={[globalSearch]}
                  textToHighlight={consumer.email}
                  key={"hl-email-" + consumer.id}
                  onClick={() => {
                    //setSelectedDevice(device);
                    //setShowDetails(true);
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                />
              </Whisper>
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={250} key="col-name">
          <Table.HeaderCell>Phone</Table.HeaderCell>
          <Table.Cell style={{ textAlign: "center" }}>
            {(consumer) => (
              <Whisper
                speaker={<Tooltip>{consumer.phone}</Tooltip>}
                placement="top"
              >
                <Highlighter
                  searchWords={[globalSearch]}
                  textToHighlight={consumer.phone}
                  key={"hl-phone-" + consumer.id}
                  onClick={() => {
                    //setSelectedDevice(device);
                    //setShowDetails(true);
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                />
              </Whisper>
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={250} key="col-name">
          <Table.HeaderCell>Device</Table.HeaderCell>
          <Table.Cell style={{ textAlign: "center" }}>
            {(consumer) => {
              const label =
                consumer && consumer.device && consumer.device.name
                  ? consumer.device.name
                  : consumer.device.imei
                  ? consumer.device.imei
                  : "N/A";
              return (
                <Whisper speaker={<Tooltip>{label}</Tooltip>} placement="top">
                  <Highlighter
                    searchWords={[globalSearch]}
                    textToHighlight={label}
                    key={"hl-device-" + consumer.id}
                    onClick={() => {
                      //setSelectedDevice(device);
                      //setShowDetails(true);
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </Whisper>
              );
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={300} key="col-name">
          <Table.HeaderCell>Vehicle</Table.HeaderCell>
          <Table.Cell style={{ textAlign: "center" }}>
            {(consumer) => (
              <span>
                {consumer.vehicle
                  ? consumer.vehicle.year +
                    " " +
                    consumer.vehicle.make +
                    " " +
                    consumer.vehicle.model
                  : "N/A"}
              </span>
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={300} fixed="right" key="col-name">
          <Table.HeaderCell>VIN</Table.HeaderCell>
          <Table.Cell style={{ textAlign: "center" }}>
            {(consumer) => {
              const vin =
                consumer.vehicle && consumer.vehicle.vin
                  ? consumer.vehicle.vin
                  : "N/A";
              //console.log({ consumer });
              return (
                <Whisper speaker={<Tooltip>{vin}</Tooltip>} placement="top">
                  <Highlighter
                    searchWords={[globalSearch]}
                    textToHighlight={vin}
                    key={"hl-vin-" + consumer.id}
                    onClick={() => {
                      //setSelectedDevice(device);
                      //setShowDetails(true);
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </Whisper>
              );
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={200} fixed="right" key="col-name">
          <Table.HeaderCell>Registered Date</Table.HeaderCell>
          <Table.Cell style={{ textAlign: "center" }}>
            {(consumer) => {
              return consumer.created_at != null
                ? moment
                    .unix(parseInt(consumer.created_at / 1000))
                    .format("MM/DD/YYYY HH:mm:ss")
                : "N/A";
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={2}>
          <Table.HeaderCell>Account Path</Table.HeaderCell>
          <Table.Cell style={{ textAlign: "left" }}>
            {(consumer) => {
              let account_id_index = -1;

              //console.log({ path: account.account_path_array });

              return (
                <div key={"ap-" + consumer.id}>
                  {consumer.account_path_array.map((s, i) => {
                    //console.log({ s, i });
                    if (s.id === user.account_id) {
                      //console.log("matched");
                      account_id_index = i;
                    }
                    //console.log({ account_id_index });
                    if (account_id_index > -1 && i >= account_id_index) {
                      if (i >= consumer.account_path_array.length - 1) {
                        return (
                          <span
                            key={"ap1-" + consumer.id + "-" + i}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              //setAccountFilter(s.id);
                              //setAccountFilterName(s.name);
                              setPage(1);
                            }}
                          >
                            <Whisper
                              speaker={<Tooltip>{s.name}</Tooltip>}
                              trigger={["hover"]}
                              placement="topEnd"
                            >
                              <Icon
                                icon={"people-group"}
                                style={{ margin: 3 }}
                                style={{ color: "#38B1C1", cursor: "pointer" }}
                              />
                            </Whisper>{" "}
                            {s.name}
                          </span>
                        );
                      } else {
                        return (
                          <span key={"api-" + s.id + "-" + i}>
                            <Whisper
                              speaker={<Tooltip>{s.name}</Tooltip>}
                              trigger={["hover"]}
                              placement="topEnd"
                            >
                              <Icon
                                icon={
                                  s.id === user.account_id
                                    ? "user"
                                    : "people-group"
                                }
                                style={{
                                  margin: 3,
                                  color: "#38B1C1",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  //setAccountFilter(s.id);
                                  //setAccountFilterName(s.name);
                                }}
                              />
                            </Whisper>
                            {" / "}
                          </span>
                        );
                      }
                    } else {
                      // Cap account path
                      //console.log({ skipped: s });
                    }
                  })}
                </div>
              );
            }}
          </Table.Cell>
        </Table.Column>
      </Table>
      <Pagination
        pages={pages}
        activePage={page}
        maxButtons={20}
        first
        last
        boundaryLinks
        ellipsis
        onSelect={(page) => {
          setPage(page);
          setOffset((page - 1) * limit);
          refetch();
        }}
        style={{
          margin: "auto",
          marginTop: 0,
          marginBottom: 0,
        }}
      />
      <Consumer
        show={showConsumer}
        id={selectedConsumer && selectedConsumer.id ? selectedConsumer.id : 0}
        setShow={setShowConsumer}
      />
    </Container>
  );
};
