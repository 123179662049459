import moment from "moment-timezone";

export default {
  getAgoString: (time) => {
    const seconds = moment
      .duration(moment.utc().diff(moment.unix(parseInt(time) / 1000)))
      .asSeconds();
    let value = 0;
    let unit = "";

    if (seconds < 60) {
      value = parseInt(seconds);
      unit = "second";
    } else if (seconds < 60 * 60) {
      value = parseInt(seconds / 60);
      unit = "minute";
    } else if (seconds < 60 * 60 * 60) {
      value = parseInt(seconds / 60 / 60);
      unit = "hour";
    } else {
      value = parseInt(seconds / 60 / 60 / 24);
      unit = "day";
    }

    return value + " " + unit + (value === 1 ? "" : "s") + " ago";
  },
};
