import gql from "graphql-tag";

export default {
  Queries: {
    device_types: gql`
      {
        device_types {
          id
          name
        }
      }
    `,
    sim_providers: gql`
      {
        sim_providers {
          id
          name
        }
      }
    `,
  },
  Mutations: {
    mass_upload: gql`
      mutation mass_upload(
        $devices: [[String!]!]
        $device_type_id: Int!
        $sim_type_id: Int
        $account_id: Int
      ) {
        mass_upload(
          devices: $devices
          device_type_id: $device_type_id
          sim_type_id: $sim_type_id
          account_id: $account_id
        )
      }
    `,
  },
};
