import React, { useState } from "react";
import { Modal, Button, Icon } from "rsuite";
import ConfirmModal from "./confirmmodal";

export default (props) => {
  const {
    onYes,
    buttonIcon,
    buttonText,
    buttonAppearance,
    buttonColor,
    buttonStyle,
    confirmText,
  } = props;
  const [showConfirm, setShowConfirm] = useState(false);

  return (
    <>
      <Button
        appearance={buttonAppearance ? buttonAppearance : "primary"}
        color={buttonColor && buttonColor}
        style={buttonStyle ? buttonStyle : {}}
        onClick={() => setShowConfirm(true)}
        className="button-shadow"
      >
        <span>
          {buttonIcon && buttonIcon + " "}
          {buttonText && buttonText}
        </span>
      </Button>
      <ConfirmModal
        show={showConfirm}
        onYes={() => {
          onYes();
          setShowConfirm(false);
        }}
        onNo={() => setShowConfirm(false)}
        text={confirmText}
      />
    </>
  );
};
