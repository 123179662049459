import React, { useCallback, useState, useEffect, useRef } from "react";
import _ from "lodash";
import { CSVLink } from "react-csv";
import {
  Container,
  Checkbox,
  Icon,
  Table,
  Panel,
  InputGroup,
  Whisper,
  Tooltip,
  Input,
  Loader,
  Pagination,
  Grid,
  Row,
  Col,
  Tag,
  Badge,
  ButtonToolbar,
  Button,
  Modal,
  Progress,
  SelectPicker,
  List,
  Form,
  FormGroup,
  Alert,
} from "rsuite";
import Highlighter from "react-highlight-words";
import CSVReader from "react-csv-reader";
import ArrayToCsv from "convert-array-to-csv";
import FileSaver from "file-saver";
import moment from "moment";
import jscookie from "js-cookie";

import { useApp } from "../../AppStore";
import tools from "../../lib/tools";

// GQL
import {
  useQuery,
  useLazyQuery,
  useApolloClient,
  useMutation,
} from "@apollo/react-hooks";
import GQL from "./graphql";

import LastReported from "./lastreported";
import BatteryLevel from "./batterylevel";
import Device from "./device";

const isMobile = window.innerWidth < 992;
const todayStart = moment()
  .utc()
  .subtract(24, "hours")
  .format("YYYY-MM-DD HH:mm:ss");
const twoDaysStart = moment()
  .utc()
  .subtract(48, "hours")
  .format("YYYY-MM-DD HH:mm:ss");
const weekStart = moment()
  .utc()
  .subtract(7, "days")
  .format("YYYY-MM-DD HH:mm:ss");
const monthStart = moment()
  .startOf("month")
  .utc()
  .format("YYYY-MM-DD HH:mm:ss");
const yearStart = moment().startOf("year").utc().format("YYYY-MM-DD HH:mm:ss");
const columnsList = [
  //"Name",
  //"IMEI",
  "SIM",
  "Carrier",
  "Enrolled Date",
  "Last Reported",
  "Battery",
  "FW Version",
  "FW Date",
  "Profile Version",
  "Profile Start",
  "Profile",
  "Recovery Mode",
  "Recovered Date",
  "New Car",
  "Registered",
];

//const isBigScreen = window.innerWidth > 1920;

//console.log({start})
const Lifespan = ({ device_id }) => {
  const { data, loading } = useQuery(GQL.Queries.device_report_count, {
    variables: { device_id },
  });

  return loading ? (
    <Loader />
  ) : data && data.device_report_count ? (
    100 -
    parseInt((Math.min(data.device_report_count, 1825) / 1825) * 100) +
    "%"
  ) : (
    "N/A"
  );
};

export default (props) => {
  const { getChildren, account_filter_id } = props;
  const limit = account_filter_id
    ? parseInt((window.innerHeight - 540) / 45)
    : parseInt((window.innerHeight - 190) / 45);
  const [
    { globalFilters, menuExpanded, globalSearch, user, globalAccountFilter },
    actions,
  ] = useApp();
  const hiddenColumnsCookieName = "um-devices-hidden-columns-" + user.id;
  const [massUploadType, setMassUploadType] = useState(5);
  const [sim_provider_id, setSim_provider_id] = useState(1);
  const [massUploadDevices, setMassUploadDevices] = useState(null);
  const [reportedToday, setReportedToday] = useState(null);
  const [reportedTwoDays, setReportedTwoDays] = useState(null);
  const [reportedThisWeek, setReportedThisWeek] = useState(null);
  const [reportedThisMonth, setReportedThisMonth] = useState(null);
  const [reportedThisYear, setReportedThisYear] = useState(null);
  const [accountFilter, setAccountFilter] = useState(
    globalAccountFilter ? globalAccountFilter.id : user.account_id
  );
  const [accountFilterName, setAccountFilterName] = useState(
    globalAccountFilter ? globalAccountFilter.name : ""
  );
  const [showEnrollModal, setShowEnrollModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [devices, setDevices] = useState([]);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const apolloClient = useApolloClient();
  const [sortColumn, setSortColumn] = useState("name");
  const [sortType, setSortType] = useState("asc");
  const [showHideColumnsModal, setShowHideColumnsModal] = useState(false);
  const [hiddenColumns, setHiddenColumns] = useState(
    jscookie.getJSON(hiddenColumnsCookieName) || []
  );
  const [moveToId, setMoveToId] = useState(null);

  const [massUpload] = useMutation(GQL.Mutations.mass_upload, {
    onCompleted: (upload) => console.log({ upload }),
  });
  const { data: reported_today_count, loading: loading_reported_today_count } =
    useQuery(GQL.Queries.reported_count, {
      variables: { start: todayStart },
      onCompleted: (data) => {
        checkAndSetReportedToday();
      },
    });
  const { data: device_types, loading: loading_device_types } = useQuery(
    GQL.Queries.device_types
  );
  const {
    data: reported_twodays_count,
    loading: loading_reported_twodays_count,
  } = useQuery(GQL.Queries.reported_count, {
    variables: { start: twoDaysStart },
    onCompleted: (data) => {
      checkAndSetReportedTwoDays();
    },
  });
  const { data: reported_week_count, loading: loading_reported_week_count } =
    useQuery(GQL.Queries.reported_count, {
      variables: { start: weekStart },
      onCompleted: (data) => {
        checkAndSetReportedThisWeek();
      },
    });
  const { data: reported_month_count, loading: loading_reported_month_count } =
    useQuery(GQL.Queries.reported_count, {
      variables: { start: monthStart },
      onCompleted: (data) => {
        checkAndSetReportedThisMonth();
      },
    });
  const { data: reported_year_count, loading: loading_reported_year_count } =
    useQuery(GQL.Queries.reported_count, {
      variables: { start: yearStart },
      onCompleted: (data) => {
        checkAndSetReportedThisYear();
      },
    });
  const { data: total, loading: loading_total } = useQuery(
    GQL.Queries.device_count,
    {
      variables: {
        get_children: Boolean(getChildren) ? getChildren : true,
      },
      onCompleted: (total) => {
        checkAndSetReportedToday();
        checkAndSetReportedTwoDays();
        checkAndSetReportedThisWeek();
        checkAndSetReportedThisMonth();
        checkAndSetReportedThisYear();
      },
    }
  );
  const {
    data: count,
    loading: loading_count,
    refetch: refetch_count,
  } = useQuery(GQL.Queries.device_count, {
    variables: account_filter_id
      ? {
          get_children: false,
          account_id: account_filter_id,
        }
      : {
          get_children: true,
          imei: globalSearch,
          sim_phone_number: globalSearch,
          account_id: accountFilter,
          name: globalSearch,
        },
    onCompleted: (data) => {
      //console.log({data, limit});
      const pages = Math.ceil(data.device_count / limit);
      setPages(pages);
      if (page > pages) {
        setPage(pages);
        setOffset(pages * limit);
      }
    },
  });
  const { data, loading, refetch } = useQuery(GQL.Queries.devices, {
    variables: account_filter_id
      ? {
          get_children: false,
          account_id: account_filter_id,
          limit,
          offset,
        }
      : {
          get_children: true,
          limit,
          offset,
          imei: globalSearch,
          sim_phone_number: globalSearch,
          account_id: accountFilter,
          name: globalSearch,
          account_name: globalSearch,
          sortType,
          sortField: sortColumn,
        },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      //console.log({devicesCount: data.devices.length});
      setDevices(data.devices);
    },
  });
  const {
    data: accounts_data,
    loading: accounts_loading,
    refetch: accounts_refetch,
  } = useQuery(GQL.Queries.accounts, {
    variables: {
      id: account_filter_id ? account_filter_id : accountFilter,
      get_children: true,
    },
  });
  const [
    load_export_data,
    { data: export_data, loading: loading_export_data },
  ] = useLazyQuery(GQL.Queries.devices_for_export);
  const {
    data: sim_providers,
    loading: loading_sim_providers,
    refetch: refetch_sim_providers,
  } = useQuery(GQL.Queries.sim_providers);
  const [moveDevices] = useMutation(GQL.Mutations.transfer_devices_using_ids);

  const handleCheckDevice = (id, checked) => {
    if (checked) {
      setSelectedIds([...selectedIds, id]);
    } else {
      let newIds = [...selectedIds];
      newIds.splice(newIds.indexOf(id), 1);
      setSelectedIds(newIds);
    }
  };

  const getLifespan = async (device_id) =>
    await apolloClient
      .query({
        query: GQL.Queries.device_report_count,
        variables: {
          device_id,
        },
      })
      .then((data) =>
        data &&
        data.device_report_count &&
        data.device_report_count.device_report_count
          ? data.device_report_count.device_report_count
          : 0
      );

  useEffect(() => {
    if (globalAccountFilter != null) actions.setGlobalAccountFilter(null);
  }, []);

  useEffect(() => {
    //console.log({accountFilter, globalSearch});
    refetch_count();
    refetch();
  }, [globalSearch, accountFilter, limit]);

  const checkAndSetReportedToday = () => {
    if (
      total &&
      total.device_count &&
      reported_today_count &&
      reported_today_count.reported_count
    ) {
      setReportedToday(
        parseInt(
          parseFloat(reported_today_count.reported_count / total.device_count) *
            100
        )
      );
    }
  };

  const checkAndSetReportedTwoDays = () => {
    if (
      total &&
      total.device_count &&
      reported_twodays_count &&
      reported_twodays_count.reported_count
    ) {
      setReportedTwoDays(
        parseInt(
          parseFloat(
            reported_twodays_count.reported_count / total.device_count
          ) * 100
        )
      );
    }
  };

  const checkAndSetReportedThisWeek = () => {
    if (
      total &&
      total.device_count &&
      reported_week_count &&
      reported_week_count.reported_count
    ) {
      setReportedThisWeek(
        parseInt(
          parseFloat(reported_week_count.reported_count / total.device_count) *
            100
        )
      );
    }
  };

  const checkAndSetReportedThisMonth = () => {
    if (
      total &&
      total.device_count &&
      reported_month_count &&
      reported_month_count.reported_count
    ) {
      setReportedThisMonth(
        parseInt(
          parseFloat(reported_month_count.reported_count / total.device_count) *
            100
        )
      );
    }
  };

  const checkAndSetReportedThisYear = () => {
    if (
      total &&
      total.device_count &&
      reported_year_count &&
      reported_year_count.reported_count
    ) {
      setReportedThisYear(
        parseInt(
          parseFloat(reported_year_count.reported_count / total.device_count) *
            100
        )
      );
    }
  };

  const onFileLoaded = (data, fileInfo) => {
    //console.log({data, fileInfo});
    setMassUploadDevices(
      data
        .filter((d) => d[0] != null)
        .map(([a, b]) => [a.toString(), b.toString()])
    );
  };

  const handleToggleColumn = (column, checked) => {
    //console.log({ column, checked });
    if (!checked) {
      setHiddenColumns([...hiddenColumns, column]);
    } else {
      let newHiddenColumns = [...hiddenColumns];
      newHiddenColumns.splice(hiddenColumns.indexOf(column), 1);
      setHiddenColumns(newHiddenColumns);
    }
  };

  useEffect(() => {
    jscookie.set(hiddenColumnsCookieName, JSON.stringify(hiddenColumns));
  }, [hiddenColumns]);

  //console.log({ accountFilter });
  //console.log({ accounts_data });

  return (
    <Container
      style={{
        paddingTop: 0,
        textAlign: "center",
      }}
    >
      {/*
      <Grid style={{width: "100%"}}>
        <Row>
          <Col xs={6} style={{textAlign: "center"}}>
            {
              reportedToday === null
              ? <Loader />
              : <div>                
                <span>{reported_today_count.reported_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} / {total.device_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Reported Today</span>
                <Progress.Line percent={reportedToday} showInfo={false} />
              </div>
            }
          </Col>
          <Col xs={6} style={{textAlign: "center"}}>
            {
              reportedTwoDays === null
              ? <Loader />
              : <div>                
                <span>{reported_twodays_count.reported_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} / {total.device_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Reported in the last 2 days</span>
                <Progress.Line percent={reportedTwoDays} showInfo={false} />
              </div>
            }
          </Col>
          <Col xs={6} style={{textAlign: "center"}}>
            {
              reportedThisWeek === null
              ? <Loader />
              : <div>
                <span>{reported_week_count.reported_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} / {total.device_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Reported This Week</span>
                <Progress.Line percent={reportedThisWeek} showInfo={false} />
              </div>
            }
          </Col>
          <Col xs={6} style={{textAlign: "center"}}>
            {
              reportedThisMonth === null
              ? <Loader />
              : <div>
                <span>{reported_month_count.reported_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} / {total.device_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Reported in {moment().format("MMMM")}</span>
                <Progress.Line percent={reportedThisMonth} showInfo={false} />
              </div>
            }
          </Col>
        </Row>
      </Grid>
          */}
      <Grid style={{ width: "100%" }}>
        <Row>
          <Col xs={8} style={{ textAlign: "left", paddingLeft: 15 }}>
            <Whisper speaker={<Tooltip>Filters</Tooltip>} placement="topStart">
              <Icon icon="filter" />
            </Whisper>{" "}
            {accountFilter !== user.account_id ? (
              <Badge content={loading_count ? "..." : count.device_count}>
                <Tag
                  className="button-shadow"
                  style={{
                    color: "white",
                    backgroundColor: "#38B1C1",
                  }}
                >
                  <Whisper
                    speaker={<Tooltip>Click to remove account filter</Tooltip>}
                    placement="topStart"
                  >
                    <span
                      style={{ cursor: "pointer", color: "white" }}
                      onClick={() => {
                        setAccountFilter(user.account_id);
                        setAccountFilterName("");
                      }}
                    >
                      <Icon icon="people-group" /> {accountFilterName}
                    </span>
                  </Whisper>
                </Tag>
              </Badge>
            ) : null}
          </Col>
          <Col xs={8}>
            {loading_count || loading_total ? (
              <Loader speed="fast" />
            ) : selectedAll || selectedIds.length > 0 ? (
              <span>
                {"Selected " +
                  (selectedAll
                    ? count.device_count
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : selectedIds.length
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")) +
                  " of " +
                  count.device_count
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                  " Devices"}
              </span>
            ) : account_filter_id ? (
              <span>
                {count.device_count
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " Devices"}
              </span>
            ) : (
              <span>
                {"Showing " +
                  count.device_count
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                  " of " +
                  total.device_count
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                  " Devices"}
              </span>
            )}
          </Col>
          <Col xs={8} style={{ textAlign: "right", paddingRight: 15 }}>
            <ButtonToolbar>
              <Button
                disabled={selectedIds.length == 0}
                size="sm"
                appearance="primary"
                onClick={() => setShowEnrollModal(true)}
              >
                <Icon icon="import" /> Move / Enroll
              </Button>
              <Button
                size="sm"
                appearance="primary"
                onClick={() => setShowHideColumnsModal(true)}
              >
                <Icon icon="columns" /> Columns
              </Button>
              <Button
                size="sm"
                appearance="primary"
                onClick={() => setShowImportModal(true)}
              >
                <Icon icon="upload" /> Import
              </Button>
              <Button
                size="sm"
                appearance="primary"
                disabled={loading_count || count.device_count > 1000}
                onClick={() => {
                  // EXPORT
                  setShowExportModal(true);
                  load_export_data({
                    variables: account_filter_id
                      ? {
                          get_children: false,
                          account_id: account_filter_id,
                          //limit,
                          //offset,
                        }
                      : {
                          get_children: Boolean(getChildren)
                            ? getChildren
                            : true,
                          //limit,
                          //offset,
                          imei: globalSearch,
                          sim_phone_number: globalSearch,
                          account_id: accountFilter,
                          name: globalSearch,
                          account_name: globalSearch,
                          sortType,
                          sortField: sortColumn,
                        },
                  });
                }}
              >
                <Icon icon="download" /> Export
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
      </Grid>
      <Table
        data={devices}
        className="button-shadow"
        style={{ borderRadius: "7px", margin: 10 }}
        onRowClick={(device) => {
          //setSelectedDevice(device);
        }}
        virtualized
        //height={window.innerHeight-80}
        autoHeight
        rowHeight={45}
        width={
          account_filter_id
            ? window.innerWidth - (menuExpanded ? 525 : 320)
            : window.innerWidth - (menuExpanded ? 275 : 70)
        }
        loading={loading}
        affixHorizontalScrollbar
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={(sortColumn, sortType) => {
          console.log({ sortColumn, sortType });
          setSortColumn(sortColumn);
          setSortType(sortType);
        }}
      >
        <Table.Column width={45} fixed="left" key="col-cb">
          <Table.HeaderCell style={{ padding: 4 }}>
            <Checkbox
              checked={selectedAll}
              onChange={(value, checked) => {
                setSelectedAll(checked);
                if (checked === false) {
                  // Clear out checked IDs
                  setSelectedIds([]);
                } else {
                  setSelectedIds(devices.map((d) => d.id));
                }
              }}
            />
          </Table.HeaderCell>
          <Table.Cell style={{ padding: 4 }}>
            {(device) => (
              <Checkbox
                key={"cb-" + device.id}
                checked={selectedIds.includes(device.id)}
                onChange={(value, checked) =>
                  handleCheckDevice(device.id, checked)
                }
              />
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={200} fixed="left" key="col-name" sortable>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.Cell style={{ textAlign: "center" }} dataKey="name">
            {(device) => (
              <Whisper
                speaker={
                  <Tooltip>
                    {device && device.name && device.name.length > 0
                      ? device.name
                      : device.imei}
                  </Tooltip>
                }
                placement="top"
              >
                <Highlighter
                  searchWords={[globalSearch]}
                  textToHighlight={
                    device && device.name && device.name.length > 0
                      ? device.name
                      : device.imei
                  }
                  key={"hl-name-" + device.id}
                  onClick={() => {
                    setSelectedDevice(device);
                    setShowDetails(true);
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                />
              </Whisper>
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={150} fixed="left" key="col-imei" sortable>
          <Table.HeaderCell>IMEI</Table.HeaderCell>
          <Table.Cell style={{ textAlign: "center" }} dataKey="imei">
            {(device) => (
              <Whisper
                placement="top"
                speaker={<Tooltip>{device.imei}</Tooltip>}
              >
                <Highlighter
                  searchWords={[globalSearch]}
                  textToHighlight={device.imei}
                  key={"hl-imei-" + device.id}
                />
              </Whisper>
            )}
          </Table.Cell>
        </Table.Column>
        {!hiddenColumns.includes("SIM") && (
          <Table.Column width={200} fixed="left" key="col-sim" sortable>
            <Table.HeaderCell>SIM / ICCID</Table.HeaderCell>
            <Table.Cell
              style={{ textAlign: "center" }}
              dataKey="sim_phone_number"
            >
              {(device) => (
                <Whisper
                  placement="top"
                  speaker={<Tooltip>{device.sim_phone_number}</Tooltip>}
                >
                  <Highlighter
                    searchWords={[globalSearch]}
                    textToHighlight={device.sim_phone_number}
                    key={"hl-sim-" + device.id}
                  />
                </Whisper>
              )}
            </Table.Cell>
          </Table.Column>
        )}
        {!hiddenColumns.includes("Carrier") && (
          <Table.Column width={150} resizable>
            <Table.HeaderCell>Carrier</Table.HeaderCell>
            <Table.Cell style={{ textAlign: "center" }}>
              {(device) => (
                <Whisper
                  placement="top"
                  speaker={<Tooltip>{device.sim_provider.name}</Tooltip>}
                >
                  <Highlighter
                    searchWords={[globalSearch]}
                    textToHighlight={device.sim_provider.name}
                    key={"hl-sp-" + device.id}
                  />
                </Whisper>
              )}
            </Table.Cell>
          </Table.Column>
        )}
        {!hiddenColumns.includes("Enrolled Date") && (
          <Table.Column width={150}>
            <Table.HeaderCell>Enrolled Date</Table.HeaderCell>
            <Table.Cell style={{ textAlign: "center" }} dataKey="enrolled_date">
              {(device) =>
                moment
                  .unix(parseInt(device.enrolled_date / 1000))
                  .format("MM/DD/YYYY hh:mm:ss A")
              }
            </Table.Cell>
          </Table.Column>
        )}
        {!hiddenColumns.includes("Last Reported") && (
          <Table.Column width={200} sortable>
            <Table.HeaderCell>Last Reported</Table.HeaderCell>
            <Table.Cell
              style={{ textAlign: "center" }}
              dataKey="last_report_date"
            >
              {(device) =>
                device.last_report_date != null
                  ? moment
                      .unix(parseInt(device.last_report_date / 1000))
                      .format("MM/DD/YYYY hh:mm:ss A")
                  : "N/A"
              }
            </Table.Cell>
          </Table.Column>
        )}
        {!hiddenColumns.includes("Battery") && (
          <Table.Column width={100}>
            <Table.HeaderCell>Battery</Table.HeaderCell>
            <Table.Cell style={{ textAlign: "center" }}>
              {(device) => {
                if (device.last_report) {
                  const last_report = JSON.parse(device.last_report);
                  if (last_report.battery_level) {
                    return last_report.battery_level + "%";
                  } else {
                    return <Lifespan device_id={device.id} />;
                  }
                }
              }}
              {/*
              device => <BatteryLevel id={device.id} />
            */}
            </Table.Cell>
          </Table.Column>
        )}
        {!hiddenColumns.includes("FW Version") && (
          <Table.Column width={100}>
            <Table.HeaderCell>FW Version</Table.HeaderCell>
            <Table.Cell style={{ textAlign: "center" }}>
              {(device) => <span>N/A</span>}
            </Table.Cell>
          </Table.Column>
        )}
        {!hiddenColumns.includes("FW Date") && (
          <Table.Column width={100}>
            <Table.HeaderCell>FW Date</Table.HeaderCell>
            <Table.Cell style={{ textAlign: "center" }}>
              {(device) => <span>N/A</span>}
            </Table.Cell>
          </Table.Column>
        )}
        {!hiddenColumns.includes("Profile Version") && (
          <Table.Column width={100}>
            <Table.HeaderCell>Profile Version</Table.HeaderCell>
            <Table.Cell style={{ textAlign: "center" }}>
              {(device) => <span>N/A</span>}
            </Table.Cell>
          </Table.Column>
        )}
        {!hiddenColumns.includes("Profile Start") && (
          <Table.Column width={100}>
            <Table.HeaderCell>Profile Start</Table.HeaderCell>
            <Table.Cell style={{ textAlign: "center" }}>
              {(device) => <span>N/A</span>}
            </Table.Cell>
          </Table.Column>
        )}
        {!hiddenColumns.includes("Profile") && (
          <Table.Column width={100}>
            <Table.HeaderCell>Profile</Table.HeaderCell>
            <Table.Cell style={{ textAlign: "center" }}>
              {(device) => <span>N/A</span>}
            </Table.Cell>
          </Table.Column>
        )}
        {!hiddenColumns.includes("Recovery Mode") && (
          <Table.Column width={100}>
            <Table.HeaderCell>Recovery Mode</Table.HeaderCell>
            <Table.Cell style={{ textAlign: "center" }}>
              {(device) => <span>N/A</span>}
            </Table.Cell>
          </Table.Column>
        )}
        {!hiddenColumns.includes("Recovered Date") && (
          <Table.Column width={100}>
            <Table.HeaderCell>Recovered Date</Table.HeaderCell>
            <Table.Cell style={{ textAlign: "center" }}>
              {(device) => <span>N/A</span>}
            </Table.Cell>
          </Table.Column>
        )}
        {!hiddenColumns.includes("New Car") && (
          <Table.Column width={100}>
            <Table.HeaderCell>New Car</Table.HeaderCell>
            <Table.Cell style={{ textAlign: "center" }}>
              {(device) => <span>N/A</span>}
            </Table.Cell>
          </Table.Column>
        )}
        {!hiddenColumns.includes("Registered") && (
          <Table.Column width={100} fixed="right">
            <Table.HeaderCell>Registered</Table.HeaderCell>
            <Table.Cell style={{ textAlign: "center" }}>
              {(device) =>
                device.registered ? (
                  <Icon icon="check-square-o" style={{ color: "#589a5d" }} />
                ) : (
                  <Icon icon="square-o" style={{ color: "lightgray" }} />
                )
              }
            </Table.Cell>
          </Table.Column>
        )}
        <Table.Column width={350} fixed="right" resizable sortable>
          <Table.HeaderCell>Account Path</Table.HeaderCell>
          <Table.Cell style={{ textAlign: "left" }} dataKey="account_name">
            {(device) => {
              let account_id_index = -1;
              return (
                <div key={"ap-" + device.id}>
                  {device.account_path_array.map((account, i) => {
                    if (account.id === user.account_id) {
                      //console.log("matched");
                      account_id_index = i;
                    }
                    //console.log({ account_id_index });
                    if (account_id_index > -1 && i >= account_id_index) {
                      if (i >= device.account_path_array.length - 1) {
                        return (
                          <span
                            key={"ap1-" + device.i + "-" + i}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setAccountFilter(account.id);
                              setAccountFilterName(account.name);
                              setPage(1);
                            }}
                          >
                            <Whisper
                              speaker={<Tooltip>{account.name}</Tooltip>}
                              trigger={["hover"]}
                              placement="topEnd"
                            >
                              <Icon
                                icon={"people-group"}
                                style={{ margin: 3 }}
                                style={{ color: "#38B1C1", cursor: "pointer" }}
                              />
                            </Whisper>{" "}
                            <Highlighter
                              searchWords={[globalSearch]}
                              textToHighlight={account.name}
                              key={"hl-account-name-" + account.id}
                              onClick={() => {
                                setAccountFilter(account.id);
                                setAccountFilterName(account.name);
                                setPage(1);
                              }}
                              style={{
                                cursor: "pointer",
                              }}
                            />
                          </span>
                        );
                      } else {
                        return (
                          <span key={"api-" + device.id + "-" + i}>
                            <Whisper
                              speaker={<Tooltip>{account.name}</Tooltip>}
                              trigger={["hover"]}
                              placement="topEnd"
                            >
                              <Icon
                                icon={
                                  account.id === user.account_id
                                    ? "user"
                                    : "people-group"
                                }
                                style={{
                                  margin: 3,
                                  color: "#38B1C1",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setAccountFilter(account.id);
                                  setAccountFilterName(account.name);
                                }}
                              />
                            </Whisper>
                            {" / "}
                          </span>
                        );
                      }
                    }
                  })}
                </div>
              );
            }}
          </Table.Cell>
        </Table.Column>
      </Table>
      <Pagination
        pages={pages}
        activePage={page}
        maxButtons={20}
        first
        last
        boundaryLinks
        ellipsis
        onSelect={(page) => {
          //console.log({page, offset: page*limit});
          setPage(page);
          setOffset((page - 1) * limit);
          refetch();
          setSelectedAll(false);
          setSelectedIds([]);
        }}
        style={{
          margin: "auto",
          marginTop: 0,
          marginBottom: 0,
        }}
      />
      <Device
        show={showDetails}
        id={selectedDevice ? selectedDevice.id : 0}
        onClose={() => setShowDetails(false)}
        sim_providers={sim_providers}
        loading_sim_providers={loading_sim_providers}
      />
      <Modal show={showExportModal}>
        <Modal.Header onHide={() => setShowExportModal(false)}>
          <Modal.Title>Export Devices</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading_export_data ? (
            <Loader content="Preparing export data..." size="md" />
          ) : export_data ? (
            <CSVLink
              filename={"Devices_Export.csv"}
              data={
                export_data && export_data.devices
                  ? export_data.devices.map((device) => {
                      return {
                        Name:
                          device.name && device.name.length > 0
                            ? device.name
                            : device.imei,
                        Registered: device.registered ? "Yes" : "No",
                        IMEI: device.imei,
                        "SIM / ICCID": device.sim_phone_number,
                        Carrier: device.sim_provider.name,
                        "Enrolled Date": moment
                          .unix(parseInt(device.enrolled_date / 1000))
                          .format("MM/DD/YYYY hh:mm:ss A"),
                        "Last Reported":
                          device.last_report_date != null
                            ? moment
                                .unix(parseInt(device.last_report_date / 1000))
                                .format("MM/DD/YYYY hh:mm:ss A")
                            : "N/A",
                        Battery: device.report_count
                          ? 100 -
                            parseInt(
                              (Math.min(device.report_count, 1825) / 1825) * 100
                            ) +
                            "%"
                          : "N/A",
                        "FW Version": "N/A",
                        "FW Date": "N/A",
                        "Profile Version": "N/A",
                        "Profile Start": "N/A",
                        Profile: "N/A",
                        "Recovery Mode": "N/A",
                        "Recovered Date": "N/A",
                        "New Car": "N/A",
                        "Account Path": device.account_path_array
                          .map((a) => a.name)
                          .join(" / "),
                      };
                    })
                  : []
              }
              onClick={() => setShowExportModal(false)}
            >
              DOWNLOAD
            </CSVLink>
          ) : (
            "Error preparing export data"
          )}
        </Modal.Body>
      </Modal>
      {/** IMPORT MODAL */}
      <Modal show={showImportModal} full>
        <Modal.Header>
          <Modal.Title>Import Devices</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <InputGroup>
              <InputGroup.Addon>Device Type</InputGroup.Addon>
              <SelectPicker
                cleanable={false}
                data={
                  !loading_device_types
                    ? device_types.device_types.map((dt) => ({
                        value: dt.id,
                        label: dt.name,
                      }))
                    : []
                }
                value={massUploadType}
                onChange={setMassUploadType}
                style={{ width: "100%" }}
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Addon>SIM Provider</InputGroup.Addon>
              <SelectPicker
                cleanable={false}
                data={
                  !loading_sim_providers
                    ? sim_providers.sim_providers.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      }))
                    : []
                }
                value={sim_provider_id}
                onChange={setSim_provider_id}
                style={{ width: "100%" }}
              />
            </InputGroup>
            <CSVReader
              label="Mass Import"
              onFileLoaded={onFileLoaded}
              parserOptions={{
                header: false,
                dynamicTyping: true,
                skipEmptyLines: true,
              }}
            />
          </div>
          {massUploadDevices != null ? (
            <div>Confirming upload of {massUploadDevices.length} devices</div>
          ) : null}
          <Table
            data={
              massUploadDevices != null
                ? massUploadDevices.map(
                    ([imei, sim_phone_number, serial_number]) => ({
                      imei,
                      sim_phone_number,
                      serial_number,
                    })
                  )
                : []
            }
            rowHeight={45}
            height={window.innerHeight - 400}
            virtualized
          >
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>IMEI</Table.HeaderCell>
              <Table.Cell dataKey="imei" />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>SIM</Table.HeaderCell>
              <Table.Cell dataKey="sim_phone_number" />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>SERIAL</Table.HeaderCell>
              <Table.Cell dataKey="serial_number" />
            </Table.Column>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          {massUploadDevices && massUploadDevices.length > 0 ? (
            <Button
              color="green"
              onClick={() => {
                massUpload({
                  variables: {
                    devices: massUploadDevices.filter((device) => device[0]),
                    device_type_id: massUploadType,
                    sim_provider_id,
                  },
                }).then((res) => {
                  if (res && res.data && res.data.mass_upload === true) {
                    setMassUploadDevices([]);
                    setShowImportModal(false);
                  }
                });
              }}
            >
              <Icon icon="import" /> Upload
            </Button>
          ) : null}
          <Button
            appearance="subtle"
            onClick={() => setShowImportModal(false)}
            color="orange"
          >
            <Icon icon="close-circle" /> Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/** COLUMNS MODAL */}
      <Modal show={showHideColumnsModal}>
        <Modal.Header onHide={() => setShowHideColumnsModal(false)}>
          <Modal.Title>Toggle Columns</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <List>
            {columnsList.map((col, index) => (
              <List.Item key={col} index={index}>
                <Grid style={{ width: "100%" }}>
                  <Row>
                    <Col xs={2}>
                      <Checkbox
                        checked={!hiddenColumns.includes(col)}
                        onChange={(value, checked) =>
                          handleToggleColumn(col, checked)
                        }
                      />
                    </Col>
                    <Col style={{ paddingTop: 8 }}>{col}</Col>
                  </Row>
                </Grid>
              </List.Item>
            ))}
          </List>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowHideColumnsModal(false)}>
            <Icon icon="ban" /> Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/** MOVE / ENROLL MODAL */}
      <Modal show={showEnrollModal}>
        <Modal.Header onHide={() => setShowEnrollModal(false)}>
          <Modal.Title>Enroll Devices</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormGroup>
              <big>Moving / Enrolling {selectedIds.length} Devices</big>
            </FormGroup>
            <FormGroup>
              <InputGroup style={{ width: "100%" }}>
                <InputGroup.Addon>
                  <Icon icon="building-o" />
                </InputGroup.Addon>
                <SelectPicker
                  loading={accounts_loading}
                  data={
                    accounts_data
                      ? accounts_data.accounts.map((a) => ({
                          label: a.name,
                          value: a.id,
                        }))
                      : []
                  }
                  style={{ width: "100%" }}
                  value={moveToId}
                  onChange={(id) => setMoveToId(id)}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Button
                disabled={moveToId == null}
                appearance="primary"
                onClick={() => {
                  moveDevices({
                    variables: {
                      account_id: moveToId,
                      ids: selectedIds,
                    },
                  }).then(() => Alert.info("Successfully moved devices."));
                  setShowEnrollModal(false);
                  setSelectedIds([]);
                  setMoveToId(null);
                }}
              >
                <Icon icon="check" /> Confirm
              </Button>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowEnrollModal(false)}>
            <Icon icon="ban" /> Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
