import React, { useState, useEffect } from "react";
import {
  Avatar,
  InputGroup,
  Input,
  Badge,
  Container,
  Icon,
  Nav,
  Navbar,
  Modal,
  Dropdown,
} from "rsuite";

import { useApp } from "../AppStore";
import useDebounce from "../hooks/usedebounce";

import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import GQL from "./graphql";
import AccountDetails from "./accounts/details";

const isMobile = window.innerWidth < 992;
const isBigScreen = window.innerWidth > 1920;

const pageTitles = {
  devices: "Devices",
  accounts: "Accounts",
  consumers: "Consumers",
};

export default (props) => {
  const { page } = props;
  const [
    {
      loggedIn,
      menuExpanded,
      globalFilters,
      globalUpdates,
      user,
      selectedPage: globalSelectedPage,
      globalSearch,
    },
    actions,
  ] = useApp();
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [search, setSearch] = useState(globalSearch);
  const debouncedSearch = useDebounce(search, 500);
  const selectedPage = page ? page : globalSelectedPage;

  useEffect(() => {
    //if (debouncedSearch) {
    actions.setGlobalSearch(search);
    //console.log("searching....");
    //}
  }, [debouncedSearch]);

  useEffect(() => {
    setSearch(globalSearch);
  }, [globalSearch]);

  return (
    <Navbar
      style={{
        width: "100%",
        backgroundColor: "#f4f4f4",
      }}
    >
      {loggedIn && (
        <Navbar.Body>
          <Nav style={{ padding: 15 }}>
            <strong>
              <big>
                {pageTitles[selectedPage] ? pageTitles[selectedPage] : ""}
              </big>
            </strong>
          </Nav>
          <Nav pullRight>
            <Dropdown
              icon={
                <span>
                  <Avatar size="xs" circle={true}>
                    <Icon icon="user" />
                  </Avatar>{" "}
                  {user && user.name ? user.name : "..."}
                </span>
              }
              trigger={["click", "hover"]}
            >
              <Dropdown.Item
                onClick={() => {
                  //actions.setTriggerAccountDetails(true)
                  setShowDetailsModal(true);
                }}
              >
                <Icon icon="cog" /> Account
              </Dropdown.Item>
              <Dropdown.Item divider />
              <Dropdown.Item onClick={() => actions.logout()}>
                <Icon icon="sign-out" /> Logout
              </Dropdown.Item>
            </Dropdown>
          </Nav>
          <Nav pullRight style={{ padding: 10 }}>
            <InputGroup>
              <Input placeholder="Search" value={search} onChange={setSearch} />
              <InputGroup.Addon>
                {search.length > 0 ? (
                  <Icon
                    icon="ban"
                    onClick={() => setSearch("")}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <Icon icon="search" />
                )}
              </InputGroup.Addon>
            </InputGroup>
          </Nav>
        </Navbar.Body>
      )}
      <AccountDetails
        show={showDetailsModal}
        setShow={setShowDetailsModal}
        //account={null}
        //refetch={null}
      />
    </Navbar>
  );
};
