import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Button, Icon, Modal, SelectPicker, Table, InputGroup } from "rsuite";
import CSVReader from "react-csv-reader";

import GQL from "./graphql";

export default (props) => {
  const { show, setShow, account } = props;

  const [massUploadType, setMassUploadType] = useState(5);
  const [massUploadDevices, setMassUploadDevices] = useState(null);
  const [sim_provider_id, setSim_provider_id] = useState(1);

  const { data: device_types, loading: loading_device_types } = useQuery(
    GQL.Queries.device_types
  );
  const { data: sim_providers, loading: loading_sim_providers } = useQuery(
    GQL.Queries.sim_providers
  );
  const [massUpload] = useMutation(GQL.Mutations.mass_upload, {
    onCompleted: (upload) => console.log({ upload }),
  });

  const onFileLoaded = (data, fileInfo) => {
    setMassUploadDevices(
      data
        .filter((d) => d[0] != null)
        .map(([a, b]) => [a.toString(), b.toString()])
    );
  };

  return (
    <Modal show={show} full>
      <Modal.Header>
        <Modal.Title>
          Import Devices into {account && account.name ? account.name : ""}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <InputGroup>
            <InputGroup.Addon>Device Type</InputGroup.Addon>
            <SelectPicker
              cleanable={false}
              data={
                !loading_device_types
                  ? device_types.device_types.map((dt) => ({
                      value: dt.id,
                      label: dt.name,
                    }))
                  : []
              }
              value={massUploadType}
              onChange={setMassUploadType}
            />
          </InputGroup>
          <InputGroup>
            <InputGroup.Addon>SIM Provider</InputGroup.Addon>
            <SelectPicker
              cleanable={false}
              data={
                !loading_sim_providers
                  ? sim_providers.sim_providers.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    }))
                  : []
              }
              value={sim_provider_id}
              onChange={setSim_provider_id}
              style={{ width: "100%" }}
            />
          </InputGroup>
          <CSVReader
            label="Mass Import"
            onFileLoaded={onFileLoaded}
            parserOptions={{
              header: false,
              dynamicTyping: true,
              skipEmptyLines: true,
            }}
          />
        </div>
        {massUploadDevices != null ? (
          <div>Confirming upload of {massUploadDevices.length} devices</div>
        ) : null}
        <Table
          data={
            massUploadDevices != null
              ? massUploadDevices.map(
                  ([imei, sim_phone_number, serial_number]) => ({
                    imei,
                    sim_phone_number,
                    serial_number,
                  })
                )
              : []
          }
          rowHeight={45}
          height={window.innerHeight - 400}
          virtualized
        >
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>IMEI</Table.HeaderCell>
            <Table.Cell dataKey="imei" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>SIM</Table.HeaderCell>
            <Table.Cell dataKey="sim_phone_number" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>SERIAL</Table.HeaderCell>
            <Table.Cell dataKey="serial_number" />
          </Table.Column>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        {massUploadDevices && massUploadDevices.length > 0 ? (
          <Button
            color="green"
            onClick={() => {
              massUpload({
                variables: {
                  devices: massUploadDevices.filter((device) => device[0]),
                  device_type_id: massUploadType,
                  account_id: account.id,
                  sim_provider_id,
                },
              }).then((res) => {
                if (res && res.data && res.data.mass_upload === true) {
                  setMassUploadDevices([]);
                  setShow(false);
                }
              });
            }}
          >
            <Icon icon="import" /> Upload
          </Button>
        ) : null}
        <Button
          appearance="subtle"
          onClick={() => setShow(false)}
          color="orange"
        >
          <Icon icon="close-circle" /> Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
