import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Container,
  InputGroup,
  Input,
  Icon,
  Tooltip,
  Whisper,
  Table,
  Loader,
  Drawer,
} from "rsuite";
import Highlighter from "react-highlight-words";
import useDebounce from "../../hooks/usedebounce";
import Device from "./device";

// GQL
import {
  useQuery,
  useLazyQuery,
  useApolloClient,
  useMutation,
} from "@apollo/react-hooks";
import GQL from "../../layout/devices/graphql";

export default (props) => {
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [showDevice, setShowDevice] = useState(false);

  const limit = parseInt((window.innerHeight - 300) / 45);
  const debouncedSearch = useDebounce(search, 500);

  const { data: total, loading: loading_total } = useQuery(
    GQL.Queries.device_count,
    {
      variables: {
        get_children: true,
      },
      onCompleted: (total) => {
        //
      },
    }
  );
  const [doSearch, { data, loading, refetch }] = useLazyQuery(
    GQL.Queries.devices,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        console.log({ devicesCount: data.devices.length });
        setDevices(data.devices);
      },
    }
  );

  useEffect(() => {
    //      console.log("searching using:", search);
    doSearch({
      variables: {
        get_children: true,
        limit,
        //offset,
        imei: search,
        //sim_phone_number: search,
        //account_id: accountFilter,
        name: search,
        //account_name: search,
        //sortType,
        //sortField: sortColumn,
      },
    });
  }, [debouncedSearch]);

  //console.log({ data });

  return (
    <Container style={{ padding: 5, paddingTop: 10 }}>
      <InputGroup>
        <Input
          placeholder="Search"
          value={search}
          onChange={setSearch}
          style={{ fontSize: 18 }}
        />
        <InputGroup.Addon>
          {search.length > 0 ? (
            <Icon
              icon="ban"
              onClick={() => setSearch("")}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <Icon icon="search" />
          )}
        </InputGroup.Addon>
      </InputGroup>
      <Container style={{ textAlign: "center", width: "100%" }}>
        <small>
          {loading ? <Loader size="xs" /> : devices.length}
          {" of "}
          {loading_total ? <Loader size="xs" /> : total.device_count}
        </small>
      </Container>
      <Table
        data={devices}
        className="button-shadow"
        style={{ borderRadius: "7px" }}
        onRowClick={(device) => {
          setSelectedDevice(device);
          setShowDevice(true);
        }}
        virtualized
        //height={window.innerHeight-80}
        autoHeight
        rowHeight={45}
        loading={loading}
        affixHorizontalScrollbar
        //sortColumn={sortColumn}
        //sortType={sortType}
        /*
        onSortColumn={(sortColumn, sortType) => {
          console.log({ sortColumn, sortType });
          setSortColumn(sortColumn);
          setSortType(sortType);
        }}*/
      >
        <Table.Column flexGrow={1} key="col-name">
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.Cell style={{ textAlign: "center" }} dataKey="name">
            {(device) => (
              <Highlighter
                searchWords={[search]}
                textToHighlight={
                  device && device.name && device.name.length > 0
                    ? device.name
                    : device.imei
                }
                key={"hl-name-" + device.id}
                onClick={() => {
                  //setSelectedDevice(device);
                  //setShowDetails(true);
                }}
                style={{
                  cursor: "pointer",
                }}
              />
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={1} key="col-imei">
          <Table.HeaderCell>IMEI</Table.HeaderCell>
          <Table.Cell style={{ textAlign: "center" }} dataKey="imei">
            {(device) => (
              <Highlighter
                searchWords={[search]}
                textToHighlight={device.imei}
                key={"hl-imei-" + device.id}
              />
            )}
          </Table.Cell>
        </Table.Column>
      </Table>
      <Drawer style={{ width: "90%" }} show={showDevice}>
        <Device device={selectedDevice} onClose={() => setShowDevice(false)} />
      </Drawer>
    </Container>
  );
};
