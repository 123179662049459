import React, { useState, useEffect } from "react";
import {
  Container,
  Modal,
  Button,
  Table,
  Grid,
  Row,
  Col,
  Icon,
  Input,
  InputGroup,
  Panel,
  Form,
  FormGroup,
  ButtonGroup,
  ButtonToolbar,
  Message,
  Alert,
  Nav,
  SelectPicker,
} from "rsuite";
import {
  useQuery,
  useMutation,
  useLazyQuery,
  useApolloClient,
} from "@apollo/react-hooks";
import CSVReader from "react-csv-reader";
import sha1 from "sha1";
import GQL from "../graphql";

const QueueMode = (props) => {
  const { modes, loading, account_id, device_type_id } = props;
  const [id, setId] = useState(0);
  const [command, setCommand] = useState(0);

  const [queue_mass_command] = useMutation(GQL.Mutations.queue_mass_command);

  //console.log({ modes });
  //console.log({ id, command });

  return (
    <>
      <SelectPicker
        cleanable={false}
        searchable={false}
        size="sm"
        data={
          modes
            ? modes.map(({ id: value, name: label }) => ({
                value,
                label,
              }))
            : []
        }
        loading={loading}
        value={id}
        onChange={(id) => {
          setId(id);
          setCommand(
            modes.filter((device_mode) => device_mode.id == id)[0].command
          );
        }}
      />
      <Button
        disabled={!id}
        size="sm"
        appearance="primary"
        onClick={() => {
          queue_mass_command({
            variables: {
              ...(account_id ? { account_id } : {}),
              device_type_id,
              command_id: id,
              command,
            },
          });
          setId(0);
          Alert.info("Commands queued");
        }}
      >
        Queue <Icon icon="send" />
      </Button>
    </>
  );
};

export default (props) => {
  const { id } = props;
  const { data, loading } = useQuery(GQL.Queries.device_type_count, {
    variables: {
      ...(id ? { id } : {}),
    },
  });
  const { data: all_commands, loading: loading_all_commands } = useQuery(
    GQL.Queries.device_modes_by_device_type
  );

  //console.log({ data });
  //console.log({ all_commands });

  return (
    <>
      <Message
        showIcon
        type="warning"
        description="Changing the report interval will affect battery life. Devices will attempt to switch modes on their next report."
      />
      <Table
        loading={loading}
        data={data && data.device_type_count ? data.device_type_count : []}
        rowHeight={50}
      >
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Device Type</Table.HeaderCell>
          <Table.Cell>{(device) => device.type}</Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Count</Table.HeaderCell>
          <Table.Cell>{(device) => device.count}</Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={2}>
          <Table.HeaderCell>Command</Table.HeaderCell>
          <Table.Cell>
            {(device) => {
              return (
                <QueueMode
                  modes={
                    all_commands && all_commands.device_modes_by_device_type
                      ? all_commands.device_modes_by_device_type.filter(
                          (device_type) =>
                            device_type.device_type_id == device.device_type_id
                        )[0].device_modes
                      : []
                  }
                  loading={loading_all_commands}
                  account_id={id}
                  device_type_id={device.device_type_id}
                />
              );
            }}
          </Table.Cell>
        </Table.Column>
      </Table>
    </>
  );
};
