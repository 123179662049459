import React, { useEffect, useState } from "react";
import { Loader } from "rsuite";
import Cookie from "js-cookie";
import "./App.css";
import "leaflet/dist/leaflet.css";

import config from "./config";

import { useApp } from "./AppStore";

// Desktop
import Header from "./layout/header";
import Login from "./layout/login";
import LoginRecharge from "./layout/login_recharge";
import Main from "./layout/main";
import Footer from "./layout/footer";
import ResetPassword from "./layout/resetpassword";

// Mobile
import MobileMain from "./mobile/main";

// GQL
import { useApolloClient } from "@apollo/react-hooks";
import gql from "graphql-tag";

const token = Cookie.get(config.session_cookie_name);

function App(props) {
  const [{ loggedIn }, actions] = useApp();
  const apolloClient = useApolloClient();
  const [appLoaded, setAppLoaded] = useState(false);
  const isMobile = window.innerWidth < 992;

  useEffect(() => {
    if (
      window.location.pathname === "/login" ||
      window.location.pathname === "/login_recharge" ||
      window.location.pathname.includes("/reset_password")
    ) {
      Cookie.set(config.session_cookie_name, null);
      setAppLoaded(true);
    } else {
      if (!token || token === "") {
        Cookie.set(config.session_cookie_name, null);
        window.location = "/logout";
      } else {
        apolloClient
          .query({
            query: gql`
              query isValidToken($token: String!) {
                isValidToken(token: $token)
              }
            `,
            variables: {
              token,
            },
          })
          .then((isValidTokenResult) => {
            if (
              !isValidTokenResult ||
              !isValidTokenResult.data ||
              !isValidTokenResult.data.isValidToken
            ) {
              Cookie.set(config.session_cookie_name, null);
              actions.logout();
              setAppLoaded(true);
              window.location = "/login";
            } else {
              setAppLoaded(true);
            }
          });
      }
    }
  }, [apolloClient, actions]);

  //console.log({ props });

  return (
    <div className="App">
      {!appLoaded ? (
        <Loader />
      ) : props.location.pathname.includes("/reset_password") ? (
        <ResetPassword {...props} />
      ) : !loggedIn ? (
        isMobile ? (
          <LoginRecharge />
        ) : (
          <Login />
        )
      ) : isMobile ? (
        <MobileMain />
      ) : (
        <Main
          page={
            props &&
            props.match &&
            props.match.params &&
            props.match.params.page
              ? props.match.params.page
              : "devices"
          }
          id={
            props && props.match && props.match.params && props.match.params.id
              ? props.match.params.id
              : 0
          }
        />
      )}
      {loggedIn && <Footer />}
    </div>
  );
}

export default App;
