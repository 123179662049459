import React from "react";
//import { Menu, Button, Pane, Text, Heading, TextInput } from "evergreen-ui";
import { Sidebar, Sidenav, Nav, Icon } from "rsuite";
import { useHistory } from "react-router-dom";

import { useApp } from "../AppStore";

export default (props) => {
  const { page } = props;
  const [{ selectedPage: globalSelectedPage, menuExpanded, user }, actions] =
    useApp();

  const selectedPage = page ? page : globalSelectedPage;
  const history = useHistory();

  let pages = [
    {
      icon: "building",
      value: "accounts",
      label: "Accounts",
    },
    {
      icon: "map-marker",
      value: "devices",
      label: "Devices",
    },
  ];
  if (user && user.account_id && user.account_id === 1) {
    pages.push({
      icon: "user",
      value: "consumers",
      label: "Consumers",
    });
  }

  return (
    <Sidebar
      style={{ backgroundColor: "#EDEDED" }}
      collapsible
      width={menuExpanded ? 250 : 45}
    >
      <Sidenav
        style={{
          backgroundColor: "#EDEDED",
        }}
        expanded={menuExpanded}
      >
        <Sidenav.Header>
          <img
            src={"images/m2mlogo.png"}
            width={250}
            onClick={() => actions.setMenuExpanded(!menuExpanded)}
          />
        </Sidenav.Header>
        <Sidenav.Body>
          <Nav>
            {pages.map((page) => (
              <Nav.Item
                key={page.value}
                eventKey={page.value}
                onClick={() => {
                  //actions.setSelectedPage(page.value);
                  history.push("/" + page.value);
                }}
                icon={
                  <Icon
                    icon={page.icon}
                    //style={{ color: page.value === selectedPage && "#8b0000" }}
                  />
                }
                active={page.value === selectedPage}
                style={{
                  backgroundColor: page.value === selectedPage && "#f4f4f4",
                  //marginLeft: page.value === selectedPage ? 0 : "15px",
                  borderTopLeftRadius: page.value === selectedPage ? "3px" : 0,
                  borderBottomLeftRadius:
                    page.value === selectedPage ? "3px" : 0,
                  //borderLeft: page.value === selectedPage ? "1px solid #e0e0e0" : 0,
                  //borderTop: page.value === selectedPage ? "1px solid #e0e0e0" : 0,
                }}
              >
                {page.label}
              </Nav.Item>
            ))}
          </Nav>
        </Sidenav.Body>
      </Sidenav>
    </Sidebar>
  );
};
