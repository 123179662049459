import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Icon,
  Form,
  FormControl,
  FormGroup,
  Input,
  InputGroup,
  Loader,
} from "rsuite";
import { useQuery, useMutation } from "@apollo/react-hooks";

import GQL from "./graphql";

export default (props) => {
  const { id, show, setShow } = props;

  const [saving, setSaving] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [year, setYear] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [vin, setVin] = useState("");

  const [update_consumer] = useMutation(GQL.Mutations.update_consumer);
  const [update_vehicle] = useMutation(GQL.Mutations.update_vehicle);
  const { data, loading, refetch } = useQuery(GQL.Queries.consumer, {
    variables: {
      id,
    },
    onCompleted: (data) => {
      if (data && data.consumer) {
        setFirstName(data.consumer.first_name);
        setLastName(data.consumer.last_name);
        setEmail(data.consumer.email);
        setPhone(data.consumer.phone);
        setAddress(data.consumer.address);
        setAddress2(data.consumer.address_2);
        setCity(data.consumer.city);
        setState(data.consumer.state);
        setZip(data.consumer.zip);
        setYear(data.consumer.vehicle.year);
        setMake(data.consumer.vehicle.make);
        setModel(data.consumer.vehicle.model);
        setVin(data.consumer.vehicle.vin);
      }
    },
  });
  const hasChanges =
    data &&
    data.consumer &&
    (data.consumer.first_name != firstName ||
      data.consumer.last_name != lastName ||
      data.consumer.email != email ||
      data.consumer.phone != phone ||
      data.consumer.address != address ||
      data.consumer.address2 != address2 ||
      data.consumer.city != city ||
      data.consumer.state != state ||
      data.consumer.zip != zip ||
      data.consumer.vehicle.year != year ||
      data.consumer.vehicle.make != make ||
      data.consumer.vehicle.model != model ||
      data.consumer.vehicle.vin != vin);

  useEffect(() => {
    refetch();
  }, [id]);

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>Consumer Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <FormGroup>
            <InputGroup style={{ width: "100%" }}>
              <InputGroup.Addon>
                <Icon icon="user" />
              </InputGroup.Addon>
              <FormControl
                value={firstName}
                onChange={setFirstName}
                placeholder="First Name"
              />
              <FormControl
                value={lastName}
                onChange={setLastName}
                placeholder="Last Name"
              />
            </InputGroup>
            <InputGroup style={{ width: "100%" }}>
              <InputGroup.Addon>
                <Icon icon="envelope" />
              </InputGroup.Addon>
              <FormControl
                value={email}
                onChange={setEmail}
                placeholder="E-Mail"
              />
            </InputGroup>
            <InputGroup style={{ width: "100%" }}>
              <InputGroup.Addon>
                <Icon icon="phone" />
              </InputGroup.Addon>
              <FormControl
                value={phone}
                onChange={setPhone}
                placeholder="Phone"
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup style={{ width: "100%" }}>
              <InputGroup.Addon>
                <Icon icon="map-signs" />
              </InputGroup.Addon>
              <FormControl
                value={address}
                onChange={setAddress}
                placeholder="Address"
              />
              <FormControl
                value={address2}
                onChange={setAddress2}
                placeholder="Address 2"
              />
            </InputGroup>
            <InputGroup style={{ width: "100%" }}>
              <InputGroup.Addon>
                <Icon icon="map" />
              </InputGroup.Addon>
              <FormControl value={city} onChange={setCity} placeholder="City" />
              <FormControl
                value={state}
                onChange={setState}
                placeholder="State"
              />
              <FormControl
                value={zip}
                onChange={setZip}
                placeholder="Zip Code"
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup style={{ width: "100%" }}>
              <InputGroup.Addon>
                <Icon icon="car" />
              </InputGroup.Addon>
              <FormControl value={year} onChange={setYear} placeholder="Year" />
              <FormControl value={make} onChange={setMake} placeholder="Make" />
              <FormControl
                value={model}
                onChange={setModel}
                placeholder="Model"
              />
            </InputGroup>
            <InputGroup style={{ width: "100%" }}>
              <InputGroup.Addon>
                <Icon icon="tag" />
              </InputGroup.Addon>
              <FormControl value={vin} onChange={setVin} placeholder="VIN" />
            </InputGroup>
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {hasChanges ? (
          <Button
            color="green"
            onClick={async () => {
              setSaving(true);
              await update_consumer({
                variables: {
                  id: data.consumer.id,
                  first_name: firstName,
                  last_name: lastName,
                  email,
                  phone,
                  address,
                  address_2: address2,
                  city,
                  state,
                  zip,
                },
              });
              await update_vehicle({
                variables: {
                  id: data.consumer.vehicle.id,
                  year,
                  make,
                  model,
                  vin,
                },
              });
              setSaving(false);
              setShow(false);
            }}
            disabled={saving}
          >
            {saving ? (
              <>
                <Loader /> Saving...
              </>
            ) : (
              <>
                <Icon icon="save" /> Save
              </>
            )}
          </Button>
        ) : (
          <Button
            color="orange"
            onClick={() => {
              setShow(false);
            }}
          >
            <Icon icon="close" /> Close
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
