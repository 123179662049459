import gql from "graphql-tag";

export default {
  Queries: {
    users: gql`
      query users($account_id: Int) {
        users(account_id: $account_id) {
          id
          name
          username
          email
          role_id
        }
      }
    `,
    account: gql`
      query account($id: Int) {
        account(id: $id) {
          id
          name
          lot_management
        }
      }
    `,
    accounts: gql`
      query accounts(
        $id: Int
        $offset: Int
        $limit: Int
        $get_children: Boolean
        $name: String
        $sortType: String
        $sortField: String
      ) {
        accounts(
          id: $id
          offset: $offset
          limit: $limit
          get_children: $get_children
          name: $name
          sortType: $sortType
          sortField: $sortField
        ) {
          id
          name
          type
          account_path_array {
            id
            name
            type
          }
          lot_management
        }
      }
    `,
    account_count: gql`
      query account_count($id: Int, $get_children: Boolean, $name: String) {
        account_count(id: $id, get_children: $get_children, name: $name)
      }
    `,
    device_count: gql`
      query device_count(
        $account_id: Int
        $get_children: Boolean
        $imei: String
        $sim_phone_number: String
        $name: String
      ) {
        device_count(
          account_id: $account_id
          get_children: $get_children
          imei: $imei
          sim_phone_number: $sim_phone_number
          name: $name
        )
      }
    `,
    reported_count: gql`
      query reported_count($account_id: Int, $start: String) {
        reported_count(account_id: $account_id, start: $start)
      }
    `,
    user_by_username: gql`
      query user_by_username($username: String!) {
        user_by_username(username: $username) {
          id
          username
        }
      }
    `,
    device_type_count: gql`
      query device_type_count($id: Int) {
        device_type_count(id: $id) {
          device_type_id
          type
          count
        }
      }
    `,
    device_modes_by_device_type: gql`
      {
        device_modes_by_device_type {
          device_type_id
          device_modes {
            id
            name
            command
          }
        }
      }
    `,
  },
  Mutations: {
    create_account: gql`
      mutation create_account(
        $parent_account_id: Int
        $name: String!
        $username: String!
        $password: String!
        $email: String!
      ) {
        create_account(
          parent_account_id: $parent_account_id
          name: $name
          username: $username
          password: $password
          email: $email
        )
      }
    `,
    create_user: gql`
      mutation create_user(
        $account_id: Int!
        $name: String!
        $username: String!
        $password: String!
        $email: String!
      ) {
        create_user(
          account_id: $account_id
          name: $name
          username: $username
          password: $password
          email: $email
        )
      }
    `,
    update_user: gql`
      mutation update_user(
        $id: Int!
        $name: String
        $password: String
        $email: String
      ) {
        update_user(id: $id, name: $name, password: $password, email: $email)
      }
    `,
    delete_user: gql`
      mutation delete_user($id: Int!) {
        delete_user(id: $id)
      }
    `,
    transfer_devices: gql`
      mutation transfer_devices($imeis: [String!]!, $account_id: Int!) {
        transfer_devices(imeis: $imeis, account_id: $account_id)
      }
    `,
    update_account: gql`
      mutation update_account(
        $id: Int!
        $name: String
        $lot_management: String
      ) {
        update_account(id: $id, name: $name, lot_management: $lot_management)
      }
    `,
    queue_mass_command: gql`
      mutation queue_mass_command(
        $account_id: Int
        $device_type_id: Int!
        $command_id: Int!
        $command: String!
      ) {
        queue_mass_command(
          account_id: $account_id
          device_type_id: $device_type_id
          command_id: $command_id
          command: $command
        )
      }
    `,
  },
};
