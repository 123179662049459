import React, { useState, useEffect } from "react";
import {
  Button,
  ButtonGroup,
  Icon,
  Container,
  Grid,
  Row,
  Col,
  Table,
  Pagination,
  Whisper,
  Progress,
  Tooltip,
  Form,
  FormGroup,
  Message,
  SelectPicker,
  InputGroup,
  Input,
} from "rsuite";
import moment from "moment";
import { useQuery, useMutation } from "@apollo/react-hooks";
import GQL from "./graphql";
import { create } from "lodash";

const limit = parseInt((window.innerHeight - 310) / 49);

export default (props) => {
  const { id, lifespan, device } = props;

  /**
   * Component States
   */
  const [page, setPage] = useState(1);
  const [index, setIndex] = useState(0);
  const [event, setEvent] = useState({});
  const [mode, setMode] = useState(device.device_mode_id);
  const [payload, setPayload] = useState("");

  const { data: data_push_command_count, loading: loading_push_command_count } =
    useQuery(GQL.Queries.push_command_count, {
      variables: {
        device_id: id,
      },
    });
  const { data, loading, refetch } = useQuery(GQL.Queries.push_commands, {
    variables: {
      device_id: id,
      offset: (page - 1) * limit,
      limit,
    },
  });
  const {
    data: device_modes,
    loading: loading_device_modes,
    refetch: refetch_device_modes,
  } = useQuery(GQL.Queries.device_modes, {
    variables: {
      device_type_id: device.device_type_id,
    },
  });
  const { data: unsent_push_commands, loading: loading_unsent_push_commands } =
    useQuery(GQL.Queries.unsent_push_commands, {
      variables: {
        device_id: id,
      },
    });
  const [create_push_command] = useMutation(GQL.Mutations.create_push_command);
  const [update_device] = useMutation(GQL.Mutations.update_device);

  useEffect(() => {
    refetch();
  }, [id, page]);

  return (
    <Grid style={{ width: "100%" }}>
      <Row>
        <Col xs={24} md={12}>
          <Table
            data={data && data.push_commands ? data.push_commands : []}
            loading={loading}
            autoHeight={true}
          >
            <Table.Column width={200}>
              <Table.HeaderCell>Command</Table.HeaderCell>
              <Table.Cell dataKey="command" />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Message</Table.HeaderCell>
              <Table.Cell dataKey="payload" />
            </Table.Column>
            <Table.Column width={100}>
              <Table.HeaderCell>Method</Table.HeaderCell>
              <Table.Cell>
                {(command) => (
                  <div>
                    {command.command_type == "socket" ? "TCP/UDP" : "SMS"}
                  </div>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={100}>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.Cell>
                {(command) => (
                  <div>{command.sent === true ? "Sent" : "Unsent"}</div>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={150}>
              <Table.HeaderCell>Date Issued</Table.HeaderCell>
              <Table.Cell>
                {(command) => (
                  <div>
                    {moment
                      .unix(parseInt(command.created_at / 1000))
                      .format("MM/DD/YYYY HH:mm:SS")}
                  </div>
                )}
              </Table.Cell>
            </Table.Column>
          </Table>
          <Container style={{ textAlign: "center", width: "100%" }}>
            <Pagination
              pages={
                !loading_push_command_count &&
                data_push_command_count &&
                data_push_command_count.push_command_count
                  ? parseInt(data_push_command_count.push_command_count / limit)
                  : 0
              }
              activePage={page}
              maxButtons={20}
              first
              last
              boundaryLinks
              ellipsis
              onSelect={(page) => {
                setPage(page);
              }}
              style={{
                margin: "auto",
                marginTop: 0,
                marginBottom: 0,
              }}
            />
          </Container>
        </Col>
        <Col xs={24} md={12}>
          <Form>
            <FormGroup>
              <Whisper speaker={<Tooltip>Lifespan</Tooltip>}>
                <Progress.Line
                  strokeColor={
                    lifespan > 60
                      ? "#27AE60"
                      : lifespan > 40
                      ? "#F39C12"
                      : lifespan > 20
                      ? "#D35400"
                      : lifespan > 0
                      ? "#D35400"
                      : "#C0392B"
                  }
                  percent={lifespan}
                />
              </Whisper>
            </FormGroup>
            <FormGroup>
              <InputGroup style={{ width: "100%" }}>
                <InputGroup.Addon>Custom Command</InputGroup.Addon>
                <Input value={payload} onChange={setPayload} />
                <InputGroup.Button
                  disabled={payload.length == 0}
                  onClick={() => {
                    create_push_command({
                      variables: {
                        device_id: id,
                        command_id: 0,
                        command_type: "socket",
                        command: "CUSTOM",
                        payload,
                      },
                    }).then(() => {
                      setPayload("");
                      refetch();
                    });
                  }}
                  color="red"
                >
                  Queue
                </InputGroup.Button>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              {!loading_unsent_push_commands &&
              unsent_push_commands.push_commands.length > 0 ? (
                <Message
                  type="info"
                  description="Pending outgoing command. Device mode may not be accurate until next report."
                  showIcon
                />
              ) : null}
              <InputGroup style={{ width: "100%" }}>
                <InputGroup.Addon>Reporting Mode</InputGroup.Addon>
                <SelectPicker
                  loading={loading_device_modes}
                  data={
                    loading_device_modes
                      ? []
                      : device_modes.device_modes.map(({ id, name }) => ({
                          value: id,
                          label:
                            id === device.device_mode_id ? "* " + name : name,
                        }))
                  }
                  value={mode}
                  onChange={(mode) => {
                    setMode(mode);
                    setPayload(
                      device_modes.device_modes.find((dm) => dm.id === mode)
                        .command
                    );
                  }}
                  style={{ width: "100%" }}
                  searchable={false}
                  cleanable={false}
                />
                {/*<InputGroup.Button
                  disabled={mode === device.device_mode_id}
                  color="blue"
                >
                  Confirm
                </InputGroup.Button>
                */}
              </InputGroup>
              {mode !== device.device_mode_id ? (
                <Message
                  type="warning"
                  description={
                    <div>
                      <div>
                        Changing reporting frequency will directly affect
                        battery life. Mode changes will take effect on the next
                        report.
                      </div>
                      <div style={{ padding: 10 }}>
                        <ButtonGroup>
                          <Button
                            appearance="primary"
                            onClick={() => {
                              create_push_command({
                                variables: {
                                  device_id: id,
                                  command_id: mode,
                                  command_type: "socket",
                                  command: "CHANGE_INTERVAL",
                                  payload,
                                },
                              }).then(() => {
                                refetch();
                              });
                              update_device({
                                variables: {
                                  id,
                                  device_mode_id: mode,
                                },
                              });
                              setMode(device.device_mode_id);
                            }}
                          >
                            <Icon icon="check" /> Confirm
                          </Button>
                          <Button
                            appearance="subtle"
                            color="orange"
                            onClick={() => {
                              setMode(device.device_mode_id);
                            }}
                          >
                            <Icon icon="ban" /> Cancel
                          </Button>
                        </ButtonGroup>
                      </div>
                    </div>
                  }
                  showIcon
                />
              ) : null}
            </FormGroup>
          </Form>
          {/*<Message type="info" description="Work in Progress" />*/}
        </Col>
      </Row>
    </Grid>
  );
};
