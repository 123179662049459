import gql from "graphql-tag";

export default {
  Queries: {
    enrolled_devices_count: gql`
      {
        enrolled_devices_count
      }
    `,
    reported_today: gql`
      {
        reported_today
      }
    `,
    reported_this_week: gql`
      {
        reported_this_week
      }
    `,
    total_device_reports: gql`
      {
        total_device_reports
      }
    `,
    geofence_count: gql`
      {
        geofence_count
      }
    `,
    devices: gql`
      {
        devices {
          last_report {
            battery_level
          }
        }
      }
    `,
    devices_in_recovery_mode: gql`
      {
        devices_in_recovery_mode
      }
    `,
    user_by_email: gql`
      query user_by_email($email: String!) {
        user_by_email(email: $email) {
          id
        }
      }
    `,
    userNeedsPasswordReset: gql`
      query userNeedsPasswordReset($username: String!) {
        userNeedsPasswordReset(username: $username)
      }
    `,
    valid_password_reset_token: gql`
      query valid_password_reset_token($token: String!) {
        valid_password_reset_token(token: $token)
      }
    `,
  },
  Mutations: {
    create_push_command: gql`
      mutation create_push_command(
        $device_id: Int!
        $command_id: Int!
        $command_type: String!
        $command: String!
        $payload: String!
      ) {
        create_push_command(
          device_id: $device_id
          command_id: $command_id
          command_type: $command_type
          command: $command
          payload: $payload
        ) {
          id
        }
      }
    `,
    update_device: gql`
      mutation update_device($id: Int!, $device_mode_id: Int) {
        update_device(id: $id, device_mode_id: $device_mode_id)
      }
    `,
    forgot_password: gql`
      mutation forgot_password($email: String!) {
        forgot_password(email: $email)
      }
    `,
    create_password_reset: gql`
      mutation create_password_reset($email: String!) {
        create_password_reset(email: $email)
      }
    `,
    reset_password_and_login: gql`
      mutation change_password_using_token(
        $token: String!
        $password: String!
      ) {
        reset_password_and_login(token: $token, password: $password) {
          token
          user {
            id
            account_id
            username
            email
            name
          }
        }
      }
    `,
    change_password_using_token: gql`
      mutation change_password_using_token(
        $token: String!
        $password: String!
      ) {
        change_password_using_token(token: $token, password: $password)
      }
    `,
  },
};
