import React from "react";
//import { Menu, Button, Pane, Text, Heading, TextInput } from "evergreen-ui";
import { Sidebar, Sidenav, Nav, Icon } from "rsuite";
import { useHistory } from "react-router-dom";

import { useApp } from "../AppStore";

const pages = [
  /*
  {
    icon: "building",
    value: "accounts",
    label: "Accounts",
  },*/
  {
    icon: "map-marker",
    value: "devices",
    label: "Devices",
  } /*
  {
    icon: "user",
    value: "consumers",
    label: "Consumers",
  },*/,
];

export default (props) => {
  const { setShowMenu } = props;

  const [
    {
      loggedIn,
      menuExpanded,
      globalFilters,
      globalUpdates,
      user,
      selectedPage,
      globalSearch,
    },
    actions,
  ] = useApp();
  const history = useHistory();

  return (
    <Sidenav
      style={{
        backgroundColor: "#FFFFFF",
      }}
    >
      <Sidenav.Body>
        <Nav>
          {pages.map((page) => (
            <Nav.Item
              key={page.value}
              eventKey={page.value}
              onClick={() => {
                actions.setSelectedPage(page.value);
                history.push("/" + page.value);
                //console.log("clicked:", page.value);
                setShowMenu(false);
              }}
              icon={<Icon icon={page.icon} style={{ color: "#38b1c1" }} />}
              active={page.value === selectedPage}
              style={{
                textAlign: "left",
                //backgroundColor: page.value === selectedPage && "#f4f4f4",
                //marginLeft: page.value === selectedPage ? 0 : "15px",
                //borderTopLeftRadius: page.value === selectedPage ? "3px" : 0,
                //borderBottomLeftRadius: page.value === selectedPage ? "3px" : 0,
                //borderLeft: page.value === selectedPage ? "1px solid #e0e0e0" : 0,
                //borderTop: page.value === selectedPage ? "1px solid #e0e0e0" : 0,
              }}
            >
              {page.label}
            </Nav.Item>
          ))}
          <Nav.Item
            onClick={() => actions.logout()}
            icon={<Icon style={{ color: "#38b1c1" }} icon="sign-out" />}
          >
            Logout
          </Nav.Item>
        </Nav>
      </Sidenav.Body>
    </Sidenav>
  );
};
