import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Container,
  Form,
  FormGroup,
  Panel,
  InputGroup,
  Input,
  Icon,
  Modal,
  ButtonToolbar,
  Message,
  Notification,
  Alert,
  Loader,
} from "rsuite";
import Cookie from "js-cookie";

// GQL
import {
  useLazyQuery,
  useMutation,
  useApolloClient,
} from "@apollo/react-hooks";
import GQL from "../graphql";

import { useApp } from "../../AppStore";

export default (props) => {
  const [{ loginError }, actions] = useApp();
  const [token, setToken] = useState(props.match.params.token);
  const client = useApolloClient();

  const doLogin = () => {
    actions.loginBySessionToken({ token, client });
  };

  useEffect(() => {
    if (token && token.length > 0) {
      doLogin();
    }
  }, []);

  return (
    <Container
      style={{
        width: "100%",
        paddingTop: "64px",
        radius: "5px",
        placeItems: "center",
      }}
    >
      <Loader description="Loading..." />
    </Container>
  );
};
