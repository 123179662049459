import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Container,
  Modal,
  Loader,
  Nav,
  Icon,
  FormGroup,
  InputGroup,
  Input,
  Form,
  FormControl,
  ControlLabel,
  Whisper,
  Tooltip,
  ButtonGroup,
  SelectPicker,
  Grid,
  Col,
  Row,
  HelpBlock,
  Table,
  Pagination,
  Radio,
} from "rsuite";
import Iframe from "react-iframe";
import { useQuery } from "@apollo/react-hooks";
import moment from "moment";
import copy from "copy-to-clipboard";
import Tools from "../../lib/tools";
import MiniMap from "./minimap";
import GQL from "./graphql";

const limit = parseInt((window.innerHeight - 310) / 49);

//console.log({ limit });

export default (props) => {
  /**
   * Local Variables
   */
  const { id } = props;

  /**
   * Component States
   */
  const [page, setPage] = useState(1);
  const [index, setIndex] = useState(0);
  const [event, setEvent] = useState({});
  const [showGoogleMaps, setShowGoogleMaps] = useState(false);
  const [googleMapsUrl, setGoogleMapsUrl] = useState("https://maps.google.com");

  /**
   * GraphQL
   */
  const { data: data_count, loading: loading_count } = useQuery(
    GQL.Queries.device_report_count,
    {
      variables: {
        device_id: id,
      },
    }
  );
  const { data, loading, refetch } = useQuery(GQL.Queries.device_reports, {
    variables: {
      device_id: id,
      offset: (page - 1) * limit,
      limit,
    },
    onCompleted: (data) => {
      if (data && data.device_reports && data.device_reports.length > index) {
        setEvent(data.device_reports[index]);
      }
    },
  });

  /*
  console.log({
    variables: {
      device_id: id,
      offset: (page-1)*limit,
      limit,
    }
  });
  console.log({data_count});
  console.log({data});
  */

  useEffect(() => {
    refetch();
  }, [id, page]);

  useEffect(() => {
    if (data && data.device_reports && data.device_reports.length > index) {
      setEvent(data.device_reports[index]);
    }
  }, [index]);

  return (
    <Grid style={{ width: "100%" }}>
      <Row>
        <Col md={12} xs={24}>
          <Table
            data={data && data.device_reports ? data.device_reports : []}
            loading={loading}
            autoHeight={true}
          >
            <Table.Column width={50}>
              <Table.HeaderCell />
              <Table.Cell style={{ padding: 5 }}>
                {(event, i) => (
                  <Radio
                    checked={index === i}
                    onClick={() => setIndex(i)}
                    style={{ margin: "auto" }}
                  />
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Report Type</Table.HeaderCell>
              <Table.Cell>
                {(event, index) => (
                  <span
                    onClick={() => setIndex(index)}
                    style={{
                      cursor: "pointer",
                      color:
                        event && event.event_type && event.event_type.color
                          ? event.event_type.color
                          : "#777777",
                    }}
                  >
                    {event && event.event_type && event.event_type.name
                      ? event.event_type.name
                      : "N/A"}
                  </span>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>GPS Date</Table.HeaderCell>
              <Table.Cell>
                {(event) =>
                  event.gps_time
                    ? moment
                        .unix(event.gps_time / 1000)
                        .format("MM/DD/YYYY hh:mm:SS A")
                    : "N/A"
                }
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Report Date</Table.HeaderCell>
              <Table.Cell>
                {(event) =>
                  moment
                    .unix(event.created_at / 1000)
                    .format("MM/DD/YYYY hh:mm:SS A")
                }
              </Table.Cell>
            </Table.Column>
            <Table.Column width={50} fixed>
              <Table.HeaderCell>RAW</Table.HeaderCell>
              <Table.Cell>
                {(event) => (
                  <Whisper
                    speaker={
                      <Tooltip>
                        Click to copy: {event.payload ? event.payload : ""}
                      </Tooltip>
                    }
                  >
                    <Icon
                      style={{ cursor: "pointer" }}
                      icon="file-text-o"
                      onClick={() => {
                        copy(event.payload ? event.payload : "");
                        Alert.info("RAW Data has been copied to clipboard!");
                      }}
                    />
                  </Whisper>
                )}
              </Table.Cell>
            </Table.Column>
          </Table>
          <Container style={{ textAlign: "center", width: "100%" }}>
            <Pagination
              pages={
                !loading_count && data_count && data_count.device_report_count
                  ? parseInt(data_count.device_report_count / limit)
                  : 0
              }
              activePage={page}
              maxButtons={10}
              first
              last
              boundaryLinks
              ellipsis
              onSelect={(page) => {
                //console.log({page, offset: page*limit});
                setPage(page);
                //refetch();
                //setSelectedAll(false);
                //setSelectedIds([]);
              }}
              style={{
                margin: "auto",
                marginTop: 0,
                marginBottom: 0,
              }}
            />
          </Container>
        </Col>
        <Col md={12} xs={24}>
          {showGoogleMaps ? (
            <Iframe
              url={googleMapsUrl}
              width="100%"
              height={window.innerHeight - 315}
              style={{
                border: "1px solid lightgray",
                borderRadius: "5px",
                //width: "100%",
                //height: window.innerHeight - 315,
              }}
              sandbox="allow-same-origin"
            />
          ) : loading || !(event && event.event_type) ? (
            <Loader />
          ) : (
            <MiniMap
              //dragging={true}
              showGoogleLink={true}
              label={
                event && event.event_type && event.event_type.name ? (
                  <Grid
                    style={{
                      width: 400, //parseInt(window.innerWidth/8)
                    }}
                  >
                    <Row style={{ borderBottom: "1px dashed lightgray" }}>
                      <Col xs={2} style={{ textAlign: "center" }}>
                        <Icon
                          icon="info-circle"
                          style={{ color: "lightgray" }}
                        />
                      </Col>
                      <Col>
                        <b>{event.event_type.name}</b>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={2} style={{ textAlign: "center" }}>
                        <Icon icon="clock-o" style={{ color: "#38b1c1" }} />
                      </Col>
                      <Col>{Tools.getAgoString(event.send_time)}</Col>
                    </Row>
                    <Row>
                      <Col xs={2} style={{ textAlign: "center" }}>
                        <Icon icon="calendar" style={{ color: "#38b1c1" }} />
                      </Col>
                      <Col>
                        {moment
                          .unix(parseInt(event.send_time / 1000))
                          .format("MM/DD/YYYY hh:mm:SS A")}
                        {event.gps_time && (
                          <span>
                            {" "}
                            <Icon
                              icon="signal"
                              style={{ color: "#38b1c1" }}
                            />{" "}
                            {moment
                              .unix(parseInt(event.gps_time / 1000))
                              .format("MM/DD/YYYY hh:mm:SS A")}
                          </span>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={2} style={{ textAlign: "center" }}>
                        <Icon icon="home" style={{ color: "#38b1c1" }} />
                      </Col>
                      <Col>
                        {event &&
                        event.location &&
                        event.location.latitude &&
                        event.location.longitude
                          ? event.address
                          : "N/A"}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={2} style={{ textAlign: "center" }}>
                        <Icon icon="map-marker" style={{ color: "#38b1c1" }} />
                      </Col>
                      <Col>
                        {event &&
                        event.location &&
                        event.location.latitude &&
                        event.location.longitude
                          ? parseFloat(event.location.latitude).toFixed(5) +
                            "," +
                            parseFloat(event.location.longitude).toFixed(5)
                          : "0,0"}
                      </Col>
                    </Row>
                    {/*
                    <Row>
                      <Col xs={2} style={{ textAlign: "center" }}>
                        <Icon icon="battery" style={{ color: "#38b1c1" }} />
                      </Col>
                      <Col>
                        {event && event.battery_level ? event.battery_level : 0}
                      </Col>
                    </Row>
                        */}
                    <Row>
                      {/*<Col xs={2} style={{textAlign: "center", verticalAlign: "top"}}><Icon icon="data-authorize" style={{color: "#38b1c1"}} /></Col>*/}
                      <Col>
                        <Input
                          componentClass="textarea"
                          rows={5}
                          value={event && event.payload ? event.payload : ""}
                          style={{
                            backgroundColor: "#f4f8fb",
                            fontFamily: "monospace",
                            color: "#333333",
                            display: "block",
                            wordBreak: "break-all",
                            overflow: "auto",
                            fontSize: 10,
                          }}
                        />
                      </Col>
                    </Row>
                  </Grid>
                ) : (
                  "N/A"
                )
              }
              coords={
                event &&
                event.location &&
                event.location.latitude &&
                event.location.longitude
                  ? {
                      lat: event.location.latitude,
                      lng: event.location.longitude,
                    }
                  : {
                      lat: 0,
                      lng: 0,
                    }
              }
              radius={
                event.event_type_id == 9 || event.event_type_id == 10
                  ? parseInt(event.location_accuracy)
                  : undefined
              }
              width="100%"
              height={window.innerHeight - 310}
              showEventType={true}
              eventTypeId={
                event && event.event_type_id ? event.event_type_id : 0
              }
              mapLabel="Report"
            />
          )}
          {/*
          <Button
            onClick={() => {
              //setShowGoogleMaps(!showGoogleMaps);
              let url = "https://www.google.com/maps/search/?api=1&query=";
              if (
                event &&
                event.location &&
                event.location.latitude &&
                event.location.longitude
              ) {
                url +=
                  "" + event.location.latitude + "," + event.location.longitude;
              } else {
                url += "0,0";
              }
              //console.log({ url });
              window.open(url);
              setGoogleMapsUrl(url);
            }}
            appearance="subtle"
          >
            {showGoogleMaps ? "Hide" : "Show" + " Google Maps"}
          </Button>*/}
        </Col>
      </Row>
    </Grid>
  );
};
