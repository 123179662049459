import React, { useState, useEffect } from "react";
import {
  Container,
  Modal,
  Button,
  Table,
  Grid,
  Row,
  Col,
  Icon,
  Input,
  InputGroup,
} from "rsuite";
import { useQuery, useMutation } from "@apollo/react-hooks";
import CSVReader from "react-csv-reader";
import GQL from "./graphql";

export default props => {
  const { show, setShow, account } = props;

  const [imei, setImei] = useState("");
  const [devices, setDevices] = useState([]);

  const [transfer_devices] = useMutation(GQL.Mutations.transfer_devices);

  //console.log({devices})

  return <Modal show={show} size="md">
    <Modal.Header>
      <Modal.Title>Transfer Devices to {account && account.name ? account.name : ""}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <InputGroup>
              <Input placeholder="Single IMEI" value={imei} onChange={setImei} />
              <InputGroup.Button onClick={() => {
                if (imei.length > 0) {
                  setDevices([...devices, {imei}]);
                  setImei("");
                }
              }} disabled={imei.length == 0}>
                <Icon icon="plus-circle" />
              </InputGroup.Button>
            </InputGroup>
          </Col>
          <Col xs={12}>
            <CSVReader
              label="Mass Import"
              onFileLoaded={(data, fileInfo) => {
                //console.log({data, fileInfo})
                setDevices([...devices, ...data.filter(line=>line[0]!=null).map(([a])=>({imei: a.toString()}))])
              }}
              parserOptions={{
                header: false,
                dynamicTyping: true,
                skipEmptyLines: true,
              }}
              inputStyle={{
                //border: "1px solid red"
              }}
            />
          </Col>
        </Row>
      </Grid>
      <Table data={devices} autoHeight virtualized>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>IMEI</Table.HeaderCell>
          <Table.Cell dataKey="imei" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>From Account</Table.HeaderCell>
          <Table.Cell dataKey="account" />
        </Table.Column>
        <Table.Column width={100}>
          <Table.HeaderCell>Actions</Table.HeaderCell>
          <Table.Cell>
            {
              device => {
                return <div>
                  <Button size="xs" appearance="subtle" color="red" onClick={()=>{setDevices(devices.filter(d=>d.imei!=device.imei))}}><Icon icon="trash" /></Button>
                </div>
              }
            }            
          </Table.Cell>
        </Table.Column>
      </Table>
    </Modal.Body>
    <Modal.Footer>
      <Button color="green" disabled={devices.length == 0} onClick={()=>{
        transfer_devices({
          variables: {
            imeis: devices.map(d=>d.imei),
            account_id: account.id
          }
        }).then(res => {
          if (res && res.data && res.data.transfer_devices === true) {
            setDevices([]);
            setShow(false);          
          }
        }).catch(err => {
          console.log({err});
        })
      }}><Icon icon="exchange" /> Transfer {devices.length}</Button>      
      <Button appearance="subtle" onClick={()=>setShow(false)} color="orange"><Icon icon="close-circle" /> Close</Button>
    </Modal.Footer>
  </Modal>
}