import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Modal,
  Loader,
  Nav,
  Icon,
  FormGroup,
  InputGroup,
  Input,
  Form,
  FormControl,
  ControlLabel,
  Whisper,
  Tooltip,
  ButtonGroup,
  SelectPicker,
  Grid,
  Col,
  Row,
  HelpBlock,
  Table,
  Progress,
  Panel,
  Alert,
  DatePicker,
} from "rsuite";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import moment from "moment";

import { useApp } from "../../AppStore";
import DeviceReports from "./devicereports";
import Commands from "./commands";
import MiniMap from "./minimap";
import Tools from "../../lib/tools";
import GQL from "./graphql";

const LIFESPAN_REPORT_COUNT = 1825;

export default (props) => {
  const { show, id, onClose, sim_providers, loading_sim_providers } = props;
  const [{ user }] = useApp();

  //console.log({ id });

  // Device States
  const [lifespan, setLifespan] = useState(0);
  const [activeTab, setActiveTab] = useState("details");
  const [name, setName] = useState("");
  const [type, setType] = useState(0);
  const [sim_provider_id, setSim_provider_id] = useState(0);
  const [attachedTo, setAttachedTo] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [model, setModel] = useState("");
  const [showRepoModal, setShowRepoModal] = useState(false);
  const [repoEmail, setRepoEmail] = useState("");
  const [expires_at, setExpires_at] = useState(
    moment.utc().add(7, "days").valueOf()
  );
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [device, setDevice] = useState(null);
  const [default_device_mode_id, setDefault_device_mode_id] = useState(0);

  console.log({ device });

  const { data, loading, refetch } = useQuery(GQL.Queries.device, {
    variables: {
      id,
    },
    onCompleted: (data) => {
      if (data && data.device) {
        //console.log({ data });
        setDefault_device_mode_id(data.device.default_device_mode_id);
        setName(data.device.name);
        setType(data.device.device_type_id);
        setAttachedTo(data.device.attached_to);
        setSim_provider_id(data.device.sim_provider_id);
        setManufacturer(data.device.manufacturer);
        setModel(data.device.model);
        setDevice({
          ...data.device,
          last_report: JSON.parse(data.device.last_report),
        });

        // Load device modes
        load_device_modes({
          variables: { device_type_id: data.device.device_type_id },
        });
      }
    },
  });
  const {
    data: repo_tokens,
    loading: loading_repo_tokens,
    refetch: refetch_repo_tokens,
  } = useQuery(GQL.Queries.repo_tokens, {
    variables: {
      device_id: id,
    },
  });
  const {
    data: device_types,
    loading: loading_device_types,
    refetch: refetch_device_types,
  } = useQuery(GQL.Queries.device_types);
  const { data: data_count, loading: loading_count } = useQuery(
    GQL.Queries.device_report_count,
    {
      variables: {
        device_id: id,
      },
      onCompleted: (data) => {
        if (data && data.device_report_count) {
          setLifespan(
            100 -
              parseInt(
                (Math.min(
                  data_count.device_report_count,
                  LIFESPAN_REPORT_COUNT
                ) /
                  LIFESPAN_REPORT_COUNT) *
                  100
              )
          );
        }
      },
    }
  );
  const [
    load_device_modes,
    { data: data_device_modes, loading: loading_device_modes },
  ] = useLazyQuery(GQL.Queries.device_modes);
  const [update_device] = useMutation(GQL.Mutations.update_device);
  const [create_repo_token] = useMutation(GQL.Mutations.create_repo_token);
  const [update_repo_token] = useMutation(GQL.Mutations.update_repo_token);
  const [disable_repo_token] = useMutation(GQL.Mutations.disable_repo_token);
  const [enable_repo_token] = useMutation(GQL.Mutations.enable_repo_token);
  const [delete_repo_token] = useMutation(GQL.Mutations.delete_repo_token);

  const hasChanges = () => {
    let changes = false;

    if (data && data.device) {
      if (data.device.name !== name) changes = true;
      if (data.device.device_type_id !== type) changes = true;
      if (data.device.sim_provider_id !== sim_provider_id) changes = true;
      if (data.device.default_device_mode_id !== default_device_mode_id)
        changes = true;
    }

    return changes;
  };

  const renderContent = () => {
    if (activeTab === "details") {
      return loading ? (
        <Loader />
      ) : (
        <Grid style={{ width: "100%" }}>
          <Row>
            <Col xs={24} md={12}>
              <Form style={{ width: "100%" }} layout="horizontal">
                {/* ACCOUNT PATH */}
                {/*
                <FormGroup>
                  <InputGroup>
                    <InputGroup.Addon>
                      <Whisper speaker={<Tooltip>Account</Tooltip>} placement="topStart">
                        <Icon icon="building-o" />
                      </Whisper>
                    </InputGroup.Addon>
                    <Input 
                      value={
                        data
                        && data.device 
                        && data.device.account_full_path                   
                        ? data.device.account_full_path
                        : ""
                      } 
                      readOnly
                    />
                  </InputGroup>
                </FormGroup>
                    */}
                {/* LIFE SPAN */}
                <FormGroup>
                  <InputGroup>
                    <InputGroup.Addon>
                      <Whisper
                        speaker={
                          <Tooltip>
                            {
                              /*device &&
                            device.device_type_id &&
                            device.device_type_id == 8
                              ? "Battery Life"
												: "Lifespan"*/
                              "Battery Life"
                            }
                          </Tooltip>
                        }
                        placement="topStart"
                      >
                        <Icon icon="battery" />
                      </Whisper>
                    </InputGroup.Addon>
                    {/*
                    <FormControl 
                      name="reportcount" 
                      value={
                        !loading_count
                        && data_count
                        && data_count.device_report_count
                        ? data_count.device_report_count
                        : "N/A"
                      } 
                      readOnly={true} 
                    />
                    */}
                    <Whisper
                      speaker={
                        <Tooltip>
                          {
                            /*device &&
                          device.device_type_id &&
                          device.device_type_id == 8 ? (
                            <>
                              Battery Level: {device.last_report.battery_level}%
                            </>
                          ) : (
                            <>
                              Report Count:{" "}
                              {!loading_count &&
                              data_count &&
                              data_count.device_report_count
                                ? data_count.device_report_count
                                : "N/A"}
                            </>
															)*/
                            "Battery Life"
                          }
                        </Tooltip>
                      }
                      placement="top"
                    >
                      {device &&
                      device.last_report &&
                      device.last_report.battery_level ? (
                        <Progress.Line
                          strokeColor={
                            device.last_report.battery_level > 60
                              ? "#27AE60"
                              : device.last_report.battery_level > 40
                              ? "#F39C12"
                              : device.last_report.battery_level > 20
                              ? "#D35400"
                              : device.last_report.battery_level > 0
                              ? "#D35400"
                              : "#C0392B"
                          }
                          percent={device.last_report.battery_level}
                        />
                      ) : (
                        <Progress.Line
                          strokeColor={
                            lifespan > 60
                              ? "#27AE60"
                              : lifespan > 40
                              ? "#F39C12"
                              : lifespan > 20
                              ? "#D35400"
                              : lifespan > 0
                              ? "#D35400"
                              : "#C0392B"
                          }
                          percent={lifespan}
                        />
                      )}
                    </Whisper>
                  </InputGroup>
                </FormGroup>
                {/* DEVICE NAME */}
                <FormGroup>
                  <InputGroup>
                    <InputGroup.Addon>
                      <Whisper
                        speaker={<Tooltip>Device Name</Tooltip>}
                        placement="topStart"
                      >
                        <Icon icon="tag" />
                      </Whisper>
                    </InputGroup.Addon>
                    <FormControl name="name" value={name} onChange={setName} />
                    {data && data.device && data.device.name !== name ? (
                      <Whisper
                        speaker={<Tooltip>Unsaved Changes!</Tooltip>}
                        placement="topStart"
                      >
                        <InputGroup.Addon>
                          <Icon
                            icon="exclamation"
                            style={{ color: "orange" }}
                          />
                        </InputGroup.Addon>
                      </Whisper>
                    ) : null}
                  </InputGroup>
                </FormGroup>
                {/* DEVICE TYPE */}
                <FormGroup>
                  <InputGroup>
                    <InputGroup.Addon>
                      <Whisper
                        speaker={<Tooltip>Device Type</Tooltip>}
                        placement="topStart"
                      >
                        <Icon icon="object-group" />
                      </Whisper>
                    </InputGroup.Addon>
                    <SelectPicker
                      loading={loading_device_types}
                      data={
                        loading_device_types
                          ? []
                          : device_types.device_types.map(({ id, name }) => ({
                              value: id,
                              label: name,
                            }))
                      }
                      //block={true}
                      value={type}
                      onChange={setType}
                      style={{ width: "100%" }}
                      searchable={false}
                      cleanable={false}
                    />
                    {data &&
                    data.device &&
                    data.device.device_type_id !== type ? (
                      <Whisper
                        speaker={<Tooltip>Unsaved Changes!</Tooltip>}
                        placement="topStart"
                      >
                        <InputGroup.Addon>
                          <Icon
                            icon="exclamation"
                            style={{ color: "orange" }}
                          />
                        </InputGroup.Addon>
                      </Whisper>
                    ) : null}
                  </InputGroup>
                </FormGroup>
                {/* ATTACHED TO */}
                <FormGroup>
                  <InputGroup>
                    <InputGroup.Addon>
                      <Whisper
                        speaker={<Tooltip>Attached To</Tooltip>}
                        placement="topStart"
                      >
                        <Icon icon="attachment" />
                      </Whisper>
                    </InputGroup.Addon>
                    <FormControl
                      name="attachedTo"
                      value={attachedTo}
                      onChange={setAttachedTo}
                    />
                    {data &&
                    data.device &&
                    data.device.attached_to !== attachedTo ? (
                      <Whisper
                        speaker={<Tooltip>Unsaved Changes!</Tooltip>}
                        placement="topStart"
                      >
                        <InputGroup.Addon>
                          <Icon
                            icon="exclamation"
                            style={{ color: "orange" }}
                          />
                        </InputGroup.Addon>
                      </Whisper>
                    ) : null}
                  </InputGroup>
                </FormGroup>
                {/* UUID */}
                <FormGroup>
                  <InputGroup>
                    <InputGroup.Addon>
                      <Whisper
                        speaker={<Tooltip>UUID</Tooltip>}
                        placement="topStart"
                      >
                        <Icon icon="tag" />
                      </Whisper>
                    </InputGroup.Addon>
                    <Input
                      value={
                        data && data.device && data.device.uuid
                          ? data.device.uuid
                          : ""
                      }
                      readOnly
                    />
                  </InputGroup>
                </FormGroup>
                {/* IMEI */}
                <FormGroup>
                  <InputGroup>
                    <InputGroup.Addon>
                      <Whisper
                        speaker={<Tooltip>IMEI</Tooltip>}
                        placement="topStart"
                      >
                        <Icon icon="tag" />
                      </Whisper>
                    </InputGroup.Addon>
                    <Input
                      value={
                        data && data.device && data.device.imei
                          ? data.device.imei
                          : ""
                      }
                      readOnly
                    />
                  </InputGroup>
                </FormGroup>
                {/* SIM */}
                <FormGroup>
                  <InputGroup>
                    <InputGroup.Addon>
                      <Whisper
                        speaker={<Tooltip>SIM Phone Number</Tooltip>}
                        placement="topStart"
                      >
                        <Icon icon="phone" />
                      </Whisper>
                    </InputGroup.Addon>
                    <Input
                      value={
                        data && data.device && data.device.sim_phone_number
                          ? data.device.sim_phone_number
                          : ""
                      }
                      readOnly
                    />
                  </InputGroup>
                </FormGroup>
                {/* SIM PROVIDER */}
                <FormGroup>
                  <InputGroup>
                    <InputGroup.Addon>
                      <Whisper
                        speaker={<Tooltip>SIM Provider</Tooltip>}
                        placement="topStart"
                      >
                        <Icon icon="object-group" />
                      </Whisper>
                    </InputGroup.Addon>
                    <SelectPicker
                      loading={loading_sim_providers}
                      data={
                        loading_sim_providers
                          ? []
                          : sim_providers.sim_providers.map(({ id, name }) => ({
                              value: id,
                              label: name,
                            }))
                      }
                      //block={true}
                      value={sim_provider_id}
                      onChange={setSim_provider_id}
                      style={{ width: "100%" }}
                      searchable={false}
                      cleanable={false}
                    />
                    {data &&
                    data.device &&
                    data.device.sim_provider_id !== sim_provider_id ? (
                      <Whisper
                        speaker={<Tooltip>Unsaved Changes!</Tooltip>}
                        placement="topStart"
                      >
                        <InputGroup.Addon>
                          <Icon
                            icon="exclamation"
                            style={{ color: "orange" }}
                          />
                        </InputGroup.Addon>
                      </Whisper>
                    ) : null}
                  </InputGroup>
                </FormGroup>
                {/* MANUFACTURER */}
                <FormGroup>
                  <InputGroup>
                    <InputGroup.Addon>
                      <Whisper
                        speaker={<Tooltip>Manufacturer</Tooltip>}
                        placement="topStart"
                      >
                        <Icon icon="tag" />
                      </Whisper>
                    </InputGroup.Addon>
                    <Input
                      value={
                        data && data.device && data.device.manufacturer
                          ? data.device.manufacturer
                          : "N/A"
                      }
                      readOnly
                    />
                  </InputGroup>
                </FormGroup>
                {/* MODEL */}
                <FormGroup>
                  <InputGroup>
                    <InputGroup.Addon>
                      <Whisper
                        speaker={<Tooltip>Model</Tooltip>}
                        placement="topStart"
                      >
                        <Icon icon="tag" />
                      </Whisper>
                    </InputGroup.Addon>
                    <Input
                      value={
                        data && data.device && data.device.model
                          ? data.device.model
                          : "N/A"
                      }
                      readOnly
                    />
                  </InputGroup>
                </FormGroup>
                {/* DEFAULT REPORT MODE */}
                <FormGroup>
                  <InputGroup>
                    <InputGroup.Addon>
                      <Whisper
                        speaker={<Tooltip>Default Reporting Mode</Tooltip>}
                        placement="topStart"
                      >
                        <Icon icon="object-group" />
                      </Whisper>
                    </InputGroup.Addon>
                    <SelectPicker
                      loading={loading_device_modes}
                      data={
                        !loading_device_modes &&
                        data_device_modes &&
                        data_device_modes.device_modes
                          ? data_device_modes.device_modes.map(
                              ({ id, name }) => ({
                                value: id,
                                label: name,
                              })
                            )
                          : []
                      }
                      //block={true}
                      value={default_device_mode_id}
                      onChange={setDefault_device_mode_id}
                      style={{ width: "100%" }}
                      searchable={false}
                      cleanable={false}
                      placeholder="No default reporting mode selected"
                    />
                    {data &&
                    data.device &&
                    data.device.default_device_mode_id !==
                      default_device_mode_id ? (
                      <Whisper
                        speaker={<Tooltip>Unsaved Changes!</Tooltip>}
                        placement="topStart"
                      >
                        <InputGroup.Addon>
                          <Icon
                            icon="exclamation"
                            style={{ color: "orange" }}
                          />
                        </InputGroup.Addon>
                      </Whisper>
                    ) : null}
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <Button
                    appearance="primary"
                    onClick={() => {
                      setShowRepoModal(true);
                      refetch_repo_tokens();
                    }}
                  >
                    <Icon icon="car" /> Create Repo Link
                  </Button>
                </FormGroup>
                <FormGroup>
                  <ButtonGroup>
                    <Button
                      color="green"
                      disabled={!hasChanges()}
                      onClick={() => {
                        //console.log("update device");
                        update_device({
                          variables: {
                            id,
                            name,
                            device_type_id: type,
                            sim_provider_id,
                            attached_to: attachedTo,
                            manufacturer,
                            model,
                            default_device_mode_id,
                          },
                        }).then((res) => {
                          //console.log({ res });
                          refetch();
                        });
                      }}
                    >
                      <span>
                        <Icon icon="save" /> Save
                      </span>
                    </Button>
                    <Button
                      color="orange"
                      onClick={() => {
                        setName(data.device.name);
                        setType(data.device.device_type_id);
                        setAttachedTo(data.device.attached_to);
                      }}
                    >
                      <span>
                        <Icon icon="ban" /> Cancel
                      </span>
                    </Button>
                  </ButtonGroup>
                </FormGroup>
              </Form>
              <Modal show={showRepoModal} full>
                <Modal.Header>
                  <Modal.Title>Repo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <InputGroup style={{ width: "100%" }}>
                    <InputGroup.Addon>
                      <Icon icon="envelope" />
                    </InputGroup.Addon>
                    <Input
                      placeholder="Email"
                      value={repoEmail}
                      onChange={setRepoEmail}
                    />
                    <InputGroup.Addon>Expires at:</InputGroup.Addon>
                    <InputGroup.Addon>
                      <DatePicker
                        onChange={setExpires_at}
                        value={expires_at}
                        disabledDate={(date) =>
                          moment().diff(moment(date), "DD/MM/YYYY") > 0
                        }
                        oneTap
                      />
                    </InputGroup.Addon>
                    <InputGroup.Addon>
                      <Button
                        disabled={repoEmail.trim().length == 0}
                        onClick={async () => {
                          const expires = await moment(expires_at).format(
                            "YYYY-MM-DD HH:mm:ss"
                          );
                          //console.log({ expires });
                          create_repo_token({
                            variables: {
                              device_id: id,
                              email: repoEmail,
                              expires_at: expires,
                            },
                          }).then((res) => {
                            setRepoEmail("");
                            refetch_repo_tokens();
                          });
                        }}
                      >
                        <Icon icon="send" /> Create
                      </Button>
                    </InputGroup.Addon>
                  </InputGroup>
                  <Table
                    loading={loading_repo_tokens}
                    data={
                      repo_tokens && repo_tokens.repo_tokens
                        ? repo_tokens.repo_tokens
                        : []
                    }
                    autoHeight={true}
                  >
                    <Table.Column flexGrow={1}>
                      <Table.HeaderCell>Email</Table.HeaderCell>
                      <Table.Cell dataKey="email" />
                    </Table.Column>
                    <Table.Column flexGrow={2}>
                      <Table.HeaderCell>Link</Table.HeaderCell>
                      <Table.Cell>
                        {(repo_token) => {
                          if (repo_token.active === false) {
                            return <span>DISABLED</span>;
                          } else if (
                            parseInt(repo_token.expires_at) < moment().valueOf()
                          ) {
                            return <span>EXPIRED</span>;
                          } else {
                            return (
                              <span>
                                <a
                                  href={
                                    "https://track.pulsewrx.com/repo/" +
                                    repo_token.token
                                  }
                                  target="_blank"
                                >
                                  {"https://track.pulsewrx.com/repo/" +
                                    repo_token.token}
                                </a>
                              </span>
                            );
                          }
                        }}
                      </Table.Cell>
                    </Table.Column>
                    <Table.Column>
                      <Table.HeaderCell>Expires</Table.HeaderCell>
                      <Table.Cell>
                        {(repo_token) =>
                          moment
                            .unix(parseInt(repo_token.expires_at / 1000))
                            .format("MM/DD/YYYY")
                        }
                      </Table.Cell>
                    </Table.Column>
                    <Table.Column width={100}>
                      <Table.HeaderCell></Table.HeaderCell>
                      <Table.Cell>
                        {(repo_token) => {
                          if (repo_token.active === false) {
                            // Disabled tokens
                            return (
                              <ButtonGroup>
                                <Whisper
                                  speaker={
                                    <Tooltip placement="left">
                                      Enable Token
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    color="green"
                                    size="xs"
                                    onClick={() => {
                                      enable_repo_token({
                                        variables: {
                                          id: repo_token.id,
                                        },
                                      }).then((res) => {
                                        if (
                                          res &&
                                          res.data &&
                                          res.data.enable_repo_token
                                        ) {
                                          refetch_repo_tokens();
                                        } else {
                                          Alert.error(
                                            "Error enabling repo token"
                                          );
                                        }
                                      });
                                    }}
                                  >
                                    <Icon icon="refresh" />
                                  </Button>
                                </Whisper>
                                <Whisper
                                  speaker={<Tooltip>Delete Token</Tooltip>}
                                >
                                  <Button
                                    color="red"
                                    size="xs"
                                    onClick={() => {
                                      delete_repo_token({
                                        variables: { id: repo_token.id },
                                      }).then((res) => {
                                        if (
                                          res &&
                                          res.data &&
                                          res.data.delete_repo_token
                                        ) {
                                          refetch_repo_tokens();
                                        } else {
                                          Alert.error(
                                            "Error deleting repo token"
                                          );
                                        }
                                      });
                                    }}
                                  >
                                    <Icon icon="trash" />
                                  </Button>
                                </Whisper>
                              </ButtonGroup>
                            );
                          } else if (
                            parseInt(repo_token.expires_at) < moment().valueOf()
                          ) {
                            return (
                              <Whisper
                                speaker={<Tooltip>Delete Token</Tooltip>}
                              >
                                <Button
                                  color="red"
                                  size="xs"
                                  onClick={() => {
                                    delete_repo_token({
                                      variables: { id: repo_token.id },
                                    }).then((res) => {
                                      if (
                                        res &&
                                        res.data &&
                                        res.data.delete_repo_token
                                      ) {
                                        refetch_repo_tokens();
                                      } else {
                                        Alert.error(
                                          "Error deleting repo token"
                                        );
                                      }
                                    });
                                  }}
                                >
                                  <Icon icon="trash" />
                                </Button>
                              </Whisper>
                            );
                          } else {
                            // Active tokens
                            return (
                              <Whisper
                                speaker={<Tooltip>Disable Token</Tooltip>}
                              >
                                <Button
                                  color="orange"
                                  size="xs"
                                  onClick={() => {
                                    disable_repo_token({
                                      variables: {
                                        id: repo_token.id,
                                      },
                                    }).then((res) => {
                                      if (
                                        res &&
                                        res.data &&
                                        res.data.disable_repo_token
                                      ) {
                                        refetch_repo_tokens();
                                      } else {
                                        Alert.error(
                                          "Error disabling repo token"
                                        );
                                      }
                                    });
                                  }}
                                >
                                  <Icon icon="ban" />
                                </Button>
                              </Whisper>
                            );
                          }
                        }}
                      </Table.Cell>
                    </Table.Column>
                  </Table>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    color="orange"
                    onClick={() => setShowRepoModal(false)}
                  >
                    <Icon icon="close" /> Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </Col>
            <Col xs={24} md={12}>
              {loading || !device ? (
                <Loader />
              ) : (
                <MiniMap
                  showGoogleLink={true}
                  label={
                    device &&
                    device.last_report &&
                    device.last_report.address &&
                    device.last_report.location &&
                    device.last_report.location.latitude &&
                    device.last_report.location.longitude ? (
                      <Grid style={{ width: parseInt(window.innerWidth / 8) }}>
                        <Row style={{ borderBottom: "1px dashed lightgray" }}>
                          <Col xs={2} style={{ textAlign: "center" }}>
                            <Icon
                              icon="info-circle"
                              style={{ color: "lightgray" }}
                            />
                          </Col>
                          <Col>
                            <b>Latest Report</b>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={2} style={{ textAlign: "center" }}>
                            <Icon icon="tag" style={{ color: "#38b1c1" }} />
                          </Col>
                          <Col>{name}</Col>
                        </Row>
                        {device.device_type_id == 8 && (
                          <Row>
                            <Col xs={2} style={{ textAlign: "center" }}>
                              <Icon
                                icon="battery"
                                style={{ color: "#38b1c1" }}
                              />
                            </Col>
                            <Col>
                              {device.last_report.battery_level}% battery
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <Col xs={2} style={{ textAlign: "center" }}>
                            <Icon icon="home" style={{ color: "#38b1c1" }} />
                          </Col>
                          <Col>{device.last_report.address}</Col>
                        </Row>
                        <Row>
                          <Col xs={2} style={{ textAlign: "center" }}>
                            <Icon
                              icon="map-marker"
                              style={{ color: "#38b1c1" }}
                            />
                          </Col>
                          <Col>
                            {parseFloat(
                              device.last_report.location.latitude
                            ).toFixed(5)}
                            ,
                            {parseFloat(
                              device.last_report.location.longitude
                            ).toFixed(5)}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={2} style={{ textAlign: "center" }}>
                            <Icon icon="clock-o" style={{ color: "#38b1c1" }} />
                          </Col>
                          <Col>
                            {Tools.getAgoString(
                              moment.utc(device.last_report.send_time).valueOf()
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={2} style={{ textAlign: "center" }}>
                            <Icon
                              icon="calendar"
                              style={{ color: "#38b1c1" }}
                            />
                          </Col>
                          <Col>
                            {moment
                              .utc(device.last_report.send_time)
                              .format("MM/DD/YYYY HH:mm:SS")}{" "}
                            <Icon icon="signal" style={{ color: "#38b1c1" }} />{" "}
                            {moment
                              .utc(device.last_report.gps_time)
                              .format("MM/DD/YYYY hh:mm:SS A")}
                          </Col>
                        </Row>
                      </Grid>
                    ) : (
                      name
                    )
                  }
                  coords={
                    device &&
                    device.last_report &&
                    device.last_report.location &&
                    device.last_report.location.latitude &&
                    device.last_report.location.longitude
                      ? {
                          lat: device.last_report.location.latitude,
                          lng: device.last_report.location.longitude,
                        }
                      : {
                          lat: 0,
                          lng: 0,
                        }
                  }
                  //radius={500}
                  width="100%"
                  height={window.innerHeight - 310}
                  showEventType={true}
                  eventTypeId={
                    device &&
                    device.last_report &&
                    device.last_report.event_type_id
                      ? device.last_report.event_type_id
                      : 0
                  }
                  mapLabel="Latest Report"
                />
              )}
            </Col>
          </Row>
        </Grid>
      );
    } else if (activeTab === "reports") {
      return loading || !data || !data.device || !data.device.id ? (
        <Loader />
      ) : (
        <DeviceReports id={data.device.id} />
      );
    } else if (activeTab === "commands") {
      return loading || !data || !data.device || !data.device.id ? (
        <Loader />
      ) : (
        <Commands
          device={data.device}
          id={data.device.id}
          lifespan={lifespan}
        />
      );
    } else if (activeTab === "consumers") {
      if (loading || !data || !data.device || !data.device.id) {
        return <Loader />;
      }

      if (data.device.consumers) {
        return (
          <Container>
            {data.device.consumers.map((consumer) => (
              <Panel
                shaded
                bordered
                bodyFill
                style={{
                  display: "inline-block",
                  width: 480,
                  marginBottom: 10,
                }}
              >
                <Panel
                  header={
                    <span>
                      {`${consumer.first_name} ${consumer.last_name}`}
                    </span>
                  }
                >
                  <p>
                    <Icon icon="envelope" />{" "}
                    {consumer.email ? consumer.email : "N/A"}
                  </p>
                  <p>
                    <Icon icon="phone" />{" "}
                    {consumer.phone ? consumer.phone : "N/A"}
                  </p>
                  <p>
                    <Icon icon="home" />{" "}
                    {consumer.address ? consumer.address : ""}
                    {consumer.address_2 ? ", " + consumer.address_2 : ""}
                    {consumer.city ? ", " + consumer.city : ""}
                    {consumer.state ? ", " + consumer.state : ""}
                    {consumer.zip ? " " + consumer.zip : ""}
                  </p>
                  <p>
                    <Icon icon="calendar" /> Registered on{" "}
                    {moment
                      .unix(parseInt(consumer.created_at / 1000))
                      .format("MM/DD/YYYY")}
                  </p>
                  {consumer.vehicle ? (
                    <p>
                      <p>
                        <Icon icon="car" />
                        {(consumer.vehicle.year
                          ? " " + consumer.vehicle.year
                          : "") +
                          (consumer.vehicle.color
                            ? " " + consumer.vehicle.color
                            : "") +
                          (consumer.vehicle.make
                            ? " " + consumer.vehicle.make
                            : "") +
                          (consumer.vehicle.model
                            ? " " + consumer.vehicle.model
                            : "")}
                      </p>
                      <p>
                        <Icon icon="tag" /> {consumer.vehicle.vin}
                      </p>
                    </p>
                  ) : null}
                </Panel>
              </Panel>
            ))}
            <Panel
              onClick={() => setShowRegisterModal(true)}
              style={{
                border: "1px dashed gray",
                cursor: "pointer",
                width: 480,
              }}
            >
              Register New Consumer
            </Panel>
            <Modal show={showRegisterModal} full>
              <Modal.Header>
                <Modal.Title>Register Consumer</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <iframe
                  src="https://register.getm2m.com/registershield"
                  style={{
                    border: 0,
                    width: "100%",
                    height: window.innerHeight - 300,
                  }}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => setShowRegisterModal(false)}
                  color="green"
                >
                  <Icon icon="check" /> Done
                </Button>
              </Modal.Footer>
            </Modal>
          </Container>
        );
      }

      return <span>No Consumers</span>;
    } else {
      return null;
    }
  };

  return (
    <Modal show={show} full>
      <Modal.Header closeButton={false}>
        <Modal.Title>
          {loading ? (
            <Loader />
          ) : data && data.device && data.device.name ? (
            <span>
              <Icon icon="building-o" />{" "}
              {data && data.device && data.device.account_full_path
                ? data.device.account_full_path + " / "
                : ""}
              <Icon icon="inbox" /> {data.device.name}
            </span>
          ) : (
            ""
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loader />
        ) : (
          <Container>
            <Nav
              activeKey={activeTab}
              onSelect={setActiveTab}
              appearance="subtle"
            >
              <Nav.Item eventKey="details" icon={<Icon icon="detail" />}>
                Details
              </Nav.Item>
              <Nav.Item eventKey="reports" icon={<Icon icon="map-marker" />}>
                Reports
              </Nav.Item>
              <Nav.Item
                eventKey="commands"
                icon={<Icon icon="terminal-line" />}
              >
                Commands
              </Nav.Item>
              {user && user.account_id && user.account_id === 1 && (
                <Nav.Item eventKey="consumers" icon={<Icon icon="user" />}>
                  Consumers
                </Nav.Item>
              )}
            </Nav>
            <Container style={{ paddingTop: "10px" }}>
              {renderContent()}
            </Container>
          </Container>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Grid style={{ width: "100%" }}>
          <Row>
            <Col xs={12} style={{ textAlign: "left" }}>
              <Button color="orange">
                <span>
                  <Icon icon="recycle" /> Set to Provisioning Mode
                </span>
              </Button>
            </Col>
            <Col xs={12}>
              <Button
                onClick={() => {
                  onClose();
                  setActiveTab("details");
                }}
                color="blue"
                appearance="subtle"
              >
                <span>
                  <Icon icon="close-circle" /> Close
                </span>
              </Button>
            </Col>
          </Row>
        </Grid>
      </Modal.Footer>
    </Modal>
  );
};
