import React, { useState } from "react";
import { Container, Drawer, Button, Grid, Row, Col, Icon } from "rsuite";

import { useApp } from "../AppStore";

import Header from "./header";
import Menu from "./menu";

import Devices from "./devices";

export default (props) => {
  const [
    {
      loggedIn,
      menuExpanded,
      globalFilters,
      globalUpdates,
      user,
      selectedPage,
      globalSearch,
    },
    actions,
  ] = useApp();
  const [showMenu, setShowMenu] = useState(false);

  return (
    <Container>
      <Container>
        <img src={"images/m2mlogo.png"} />
      </Container>
      <Container style={{ padding: 15 }}>
        <Grid style={{ width: "100%" }}>
          <Row>
            <Col xs={4} style={{ textAlign: "left" }}>
              <Button
                onClick={() => setShowMenu(true)}
                style={{ color: "#38b1c1" }}
              >
                <Icon icon="list-ul" />
              </Button>
            </Col>
            <Col xs={16} style={{ textAlign: "center", paddingTop: 8 }}>
              <big>
                {selectedPage[0].toUpperCase() + selectedPage.substring(1)}
              </big>
            </Col>
            <Col xs={4} style={{ textAlign: "right" }}>
              {/*
              <Button
                onClick={() => {
                  console.log("click");
                }}
                style={{ color: "#38b1c1" }}
              >
                <Icon icon="plus" />
              </Button>
              */}
            </Col>
          </Row>
        </Grid>
        {selectedPage == "devices" && <Devices />}
      </Container>
      <Drawer
        placement="left"
        show={showMenu}
        style={{ width: "80%" }}
        className="header"
      >
        <Header />
        <Menu setShowMenu={setShowMenu} />
      </Drawer>
    </Container>
  );
};
