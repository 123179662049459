import React, { useState } from "react";
import {
  Animation,
  Alert,
  Button,
  Container,
  Form,
  FormGroup,
  InputGroup,
  Input,
  Icon,
  Tooltip,
  Whisper,
  Table,
  Loader,
  Panel,
  Grid,
  Row,
  Col,
  Progress,
  Modal,
  Slider,
} from "rsuite";
import moment from "moment-timezone";

import {
  useQuery,
  useLazyQuery,
  useApolloClient,
  useMutation,
} from "@apollo/react-hooks";

import ButtonWithConfirm from "../../components/buttonwithconfirm";
import MiniMap from "./minimap";

import GQL from "../../layout/devices/graphql";

import Tools from "../../lib/tools";

const LIFESPAN_REPORT_COUNT = 1825;

export default (props) => {
  const { device, onClose } = props;

  const [lifespan, setLifespan] = useState(0);
  const [reportCount, setReportCount] = useState(0);
  const [lastReport, setLastReport] = useState(JSON.parse(device.last_report));
  const [expanded, setExpanded] = useState(false);
  const [expandedModes, setExpandedModes] = useState(false);

  const { data: data_count, loading: loading_count } = useQuery(
    GQL.Queries.device_report_count,
    {
      variables: {
        device_id: device.id ? device.id : 0,
      },
      onCompleted: (data) => {
        if (data && data.device_report_count) {
          setReportCount(data.device_report_count);
          setLifespan(
            100 -
              parseInt(
                (Math.min(
                  data_count.device_report_count,
                  LIFESPAN_REPORT_COUNT
                ) /
                  LIFESPAN_REPORT_COUNT) *
                  100
              )
          );
        }
      },
    }
  );
  const {
    data: device_modes,
    loading: loading_device_modes,
    refetch: refetch_device_modes,
  } = useQuery(GQL.Queries.device_modes, {
    variables: {
      device_type_id: device.device_type_id,
    },
  });

  const { data, loading, refetch } = useQuery(GQL.Queries.device_reports, {
    variables: {
      device_id: device.id,
      //offset: 0,
      limit: 10,
    },
    onCompleted: (data) => {
      // TODO: TBD
      console.log({ data });
    },
  });

  const [create_push_command] = useMutation(GQL.Mutations.create_push_command);
  const [update_device] = useMutation(GQL.Mutations.update_device);

  //console.log({ device });
  //console.log({ device_modes });
  //console.log({ incoming: data });
  console.log({ lastReport });

  return (
    <Container style={{ padding: 10 }}>
      <Grid fluid style={{ width: "100%" }}>
        <Row>
          <Col style={{ textAlign: "left" }} xs={4}>
            <Button onClick={onClose}>
              <Icon icon="back-arrow" style={{ color: "#38b1c1" }} />
            </Button>
          </Col>
          <Col xs={20} style={{ paddingTop: 7 }}>
            <strong>
              <big>{device.name}</big>
            </strong>
          </Col>
        </Row>
      </Grid>
      <Panel
        className="header"
        style={{
          backgroundColor: "#38b1c1",
          color: "white",
          padding: 10,
          paddingBottom: 0,
        }}
        //onClick={() => setExpanded(!expanded)}
        bodyFill
      >
        <Grid
          fluid
          style={{ width: "100%" }}
          onClick={() => setExpanded(!expanded)}
        >
          <Row>
            <Col style={{ margin: 0, padding: 0 }}>
              <Progress.Line
                strokeColor={
                  lastReport && lastReport.battery_level
                    ? lastReport.battery_level > 60
                      ? "#27AE60"
                      : lastReport.battery_level > 40
                      ? "#F39C12"
                      : lastReport.battery_level > 20
                      ? "#D35400"
                      : lastReport.battery_level > 0
                      ? "#D35400"
                      : "#C0392B"
                    : lifespan > 60
                    ? "#27AE60"
                    : lifespan > 40
                    ? "#F39C12"
                    : lifespan > 20
                    ? "#D35400"
                    : lifespan > 0
                    ? "#D35400"
                    : "#C0392B"
                }
                percent={
                  lastReport && lastReport.battery_level
                    ? lastReport.battery_level
                    : lifespan
                }
                showInfo={false}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={2}>
              <Icon icon="battery" />
            </Col>
            <Col xs={10} style={{ textAlign: "left" }}>
              {(lastReport && lastReport.battery_level
                ? lastReport.battery_level
                : lifespan) + "%"}
            </Col>
            <Col xs={10} style={{ textAlign: "right" }}>
              {lastReport && lastReport.battery_level
                ? reportCount
                : reportCount + " / " + LIFESPAN_REPORT_COUNT}
            </Col>
            <Col xs={2}>
              <Icon icon="list" />
            </Col>
          </Row>
        </Grid>
        <Animation.Collapse in={expanded}>
          <Grid
            fluid
            style={{
              width: "100%",
            }}
          >
            <Row>
              <Col xs={2}>
                <Icon icon="tag" />
              </Col>
              <Col xs={10} style={{ textAlign: "left" }}>
                {device.name}
              </Col>
              <Col xs={10} style={{ textAlign: "right" }}>
                {device.imei}
              </Col>
              <Col xs={2}>
                <Icon icon="tag" />
              </Col>
            </Row>
            <Row>
              <Col xs={2}>
                <Icon icon="phone" />
              </Col>
              <Col xs={20} style={{ textAlign: "left" }}>
                {device.sim_phone_number}
              </Col>
            </Row>
            <Row>
              <Col xs={2}>
                <Icon icon="list" />
              </Col>
              <Col xs={20} style={{ textAlign: "left" }}>
                {loading_device_modes
                  ? "Loading..."
                  : (
                      device_modes.device_modes.find(
                        (mode) => mode.id == device.device_mode_id
                      ) || [{ name: "N/A" }]
                    ).name}{" "}
                Reporting Mode
              </Col>
            </Row>
            <Row>
              <Col xs={2}>
                <Icon icon="clock-o" />
              </Col>
              <Col xs={20} style={{ textAlign: "left" }}>
                {lastReport &&
                  lastReport.send_time &&
                  "Reported " +
                    Tools.getAgoString(
                      moment.utc(lastReport.send_time).valueOf()
                    )}
              </Col>
            </Row>
            <Row>
              <Col xs={2}>
                <Icon icon="map-marker" />
              </Col>
              <Col xs={20} style={{ textAlign: "left" }}>
                {lastReport &&
                  lastReport.location &&
                  lastReport.location.latitude &&
                  lastReport.location.longitude &&
                  lastReport.location.latitude.toFixed(5) +
                    ", " +
                    lastReport.location.longitude.toFixed(5)}
              </Col>
            </Row>
            <Row>
              <Col xs={2}>
                <Icon icon="envelope" />
              </Col>
              <Col xs={20} style={{ textAlign: "left" }}>
                {lastReport && lastReport.address && lastReport.address}
              </Col>
            </Row>
            <Row>
              <Col xs={24} style={{ textAlign: "center" }}>
                {expanded &&
                  lastReport &&
                  lastReport.location &&
                  lastReport.location.latitude &&
                  lastReport.location.longitude && (
                    <MiniMap
                      //dragging={true}
                      //showGoogleLink={true}
                      label={
                        lastReport &&
                        lastReport.send_time &&
                        Tools.getAgoString(
                          moment.utc(lastReport.send_time).valueOf()
                        )
                      }
                      coords={
                        lastReport &&
                        lastReport.location &&
                        lastReport.location.latitude &&
                        lastReport.location.longitude
                          ? {
                              lat: lastReport.location.latitude,
                              lng: lastReport.location.longitude,
                            }
                          : {
                              lat: 0,
                              lng: 0,
                            }
                      }
                      //radius={500}
                      width="100%"
                      height={240}
                      /*
                      showEventType={true}
                      eventTypeId={
                        lastReport && lastReport.event_type_id
                          ? lastReport.event_type_id
                          : 0
                      }
                      */
                      //mapLabel="Report"
                    />
                  )}
              </Col>
            </Row>
            <Row>
              <Col
                xs={24}
                style={{
                  textAlign: "center",
                  padding: 8,
                  paddingTop: 16,
                  paddingBottom: 16,
                }}
              >
                <Slider
                  graduated
                  max={
                    data && data.device_reports && data.device_reports.length
                      ? data.device_reports.length - 1
                      : 0
                  }
                  showInfo
                  onChange={(value) => {
                    const report = data.device_reports[value];
                    setLastReport({
                      ...report,
                      gps_time: moment
                        .unix(parseInt(report.gps_time / 1000))
                        .utc()
                        .format("YYYY/MM/DD HH:mm:ss"),
                      send_time: moment
                        .unix(parseInt(report.send_time / 1000))
                        .utc()
                        .format("YYYY/MM/DD HH:mm:ss"),
                    });
                  }}
                />
              </Col>
            </Row>
          </Grid>
        </Animation.Collapse>
        <Grid onClick={() => setExpanded(!expanded)}>
          <Row>
            <Col xs={24} style={{ textAlign: "center" }}>
              <Icon icon={expanded ? "arrow-up" : "arrow-down"} />
            </Col>
          </Row>
        </Grid>
      </Panel>
      <Panel
        className="header"
        style={{
          backgroundColor: "#38b1c1",
          color: "white",
          padding: 10,
          paddingBottom: 0,
          marginTop: 10,
        }}
        onClick={() => setExpandedModes(!expandedModes)}
        bodyFill
      >
        <Container
          style={{
            width: "100%",
            fontWeight: "bold",
            textAlign: "center",
            padding: 8,
            paddingBottom: 16,
          }}
        >
          Modes
        </Container>
        <Animation.Collapse in={expandedModes}>
          <Grid fluid style={{ width: "100%" }}>
            {loading_device_modes ? (
              <Loader />
            ) : (
              device_modes.device_modes.map((mode) => (
                <Row>
                  <Col xs={24}>
                    <ButtonWithConfirm
                      buttonAppearance="primary"
                      buttonColor="blue"
                      buttonStyle={{ marginBottom: 10, width: "100%" }}
                      buttonText={mode.name}
                      confirmText={
                        "Changing report modes will affect battery life. Are you sure you want to change the reporting mode to " +
                        mode.name +
                        "?"
                      }
                      onYes={() => {
                        create_push_command({
                          variables: {
                            device_id: device.id,
                            command_id: mode.id,
                            command_type: "socket",
                            command: "CHANGE_INTERVAL",
                            payload: mode.command,
                          },
                        }).then((res) => {
                          update_device({
                            variables: {
                              id: device.id,
                              device_mode_id: mode.id,
                            },
                          });
                        });
                      }}
                    />
                  </Col>
                </Row>
              ))
            )}
          </Grid>
        </Animation.Collapse>
        <Grid>
          <Row>
            <Col xs={24} style={{ textAlign: "center" }}>
              <Icon icon={expandedModes ? "arrow-up" : "arrow-down"} />
            </Col>
          </Row>
        </Grid>
      </Panel>
    </Container>
  );
};
