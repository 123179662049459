import React from "react";
import { Modal, Button, Icon } from "rsuite";

export default (props) => {
  const { show, onYes, onNo, text } = props;
  return (
    <Modal show={show} full style={{ width: "100%" }}>
      <Modal.Header>Confirm</Modal.Header>
      <Modal.Body>
        {text ? text : "Are you sure you want to complete this action?"}
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="primary" color="green" onClick={onYes} size="lg">
          <span>
            <Icon icon="check" /> Yes
          </span>
        </Button>
        <Button appearance="subtle" color="red" onClick={onNo} size="lg">
          <span>
            <Icon icon="ban" /> No
          </span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
