import React from "react";
import { Container, Content } from "rsuite";

import Header from "./header";
import Menu from "./menu";
import Devices from "./devices";
import Accounts from "./accounts";
import Consumers from "./consumers";

import { useApp } from "../AppStore";

const isBigScreen = window.innerWidth > 1920;

export default (props) => {
  const { page, id } = props;
  const [
    { selectedPage: globalSelectedPage, menuExpanded },
    actions,
  ] = useApp();
  //const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);
  //const isMobile = window.innerWidth < 992;

  const selectedPage = page ? page : globalSelectedPage;

  return (
    <Container style={{ height: "100vh" }}>
      <Menu page={selectedPage} />
      <Container>
        <Header page={selectedPage} />
        {selectedPage == "devices" && <Devices id={id} />}
        {selectedPage == "accounts" && <Accounts id={id} />}
        {selectedPage == "consumers" && <Consumers id={id} />}
      </Container>
    </Container>
  );
};
